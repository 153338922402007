import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { none, isNil } from 'ramda';
import Maps from '@/assets/img/maps_icon.svg';
import useToggle from '@/hooks/useToggle';
import Card from '../components/Card';
import LabelInput from '../components/LabelInput';
import LabelToggle from '../components/LabelToggle';
import LabelTextarea from '../components/LabelTextarea';
import CitySearchInput from '../components/CitySearchInput';
import StreetSearchInput from '../components/StreetSearchInput';
import ParkingAreaSelector from './ParkingAreaSelector';
import RoadTypeSelector from './RoadTypeSelector';
import MapModal from './MapModal';
import { useEnforcementCities } from '../../index';

export const GET_ORG_USE_ENF_CITIES = gql`
  query GetOrgUseEnfCities {
    currentOrganisation {
      useEnforcementCities
    }
  }
`;

const StyledCard = styled(Card)`
  padding-top: 0;

  > h2 {
    padding: 0;

    span {
      margin: 7px 0 0;
    }

    button {
      width: 40px;
      min-height: 30px;
      margin: 3px 0 0 3px;
      background: none;
      border: none;

      &:focus {
        outline: none;
      }

      img {
        width: 100%;
      }
    }
  }
`;

const SplitContainer = styled.div`
  label:last-of-type {
    min-width: 120px;
    margin-left: 12px;
  }
`;

const LocationCard = ({ formik, disabled = false }) => {
  const offenseLocationHousenumberAdditionProps = formik.getFieldProps(
    'offenseLocationHousenumberAddition'
  );
  const {
    offenseLocationCity,
    type,
    offenseLocationWithinCityLimits,
    offenseLocationHousenumber,
    offenseLocationStreet,
    offenseLatitude,
    offenseLongitude
  } = formik.values;
  const { data: organisationData } = useQuery(GET_ORG_USE_ENF_CITIES);
  const [mapIsOpen, toggleMap] = useToggle();

  const locationPresent = none(isNil, [offenseLatitude, offenseLongitude]);

  return (
    <StyledCard
      customHeader={
        <h2 className="flex-start">
          <span>{I18n.t('tickets.form.sections.offense.location')}</span>
          {locationPresent && (
            <button onClick={toggleMap}>
              <img src={Maps} alt="Maps" />
            </button>
          )}
        </h2>
      }
      colorScheme={{ primaryColor: '#31578C', secondaryColor: '#F6F7FC' }}
    >
      {formik.values.type === 'fiscal' && (
        <ParkingAreaSelector
          disabled={disabled}
          readOnly={formik.status.readOnly}
          error={formik.errors.offenseParkingArea}
          {...formik.getFieldProps('offenseParkingArea')}
        />
      )}
      <CitySearchInput
        label={I18n.t('activerecord.attributes.ticket.offense_location_city')}
        disabled={disabled}
        readOnly={formik.status.readOnly}
        error={formik.errors.offenseLocationCity}
        fieldProps={formik.getFieldProps('offenseLocationCity')}
        cityType={
          organisationData?.currentOrganisation?.useEnforcementCities ? useEnforcementCities : ''
        }
      />
      <StreetSearchInput
        disabled={disabled || !offenseLocationCity}
        readOnly={formik.status.readOnly}
        error={formik.errors.offenseLocationStreet}
        city={offenseLocationCity}
        fieldProps={formik.getFieldProps('offenseLocationStreet')}
      />
      <SplitContainer className="flex-between">
        <LabelInput
          readOnly={formik.status.readOnly}
          label={I18n.t('tickets.form.sections.suspect.house_number')}
          {...formik.getFieldProps('offenseLocationHousenumber')}
        />
        <LabelInput
          readOnly={formik.status.readOnly}
          label={I18n.t('tickets.form.sections.suspect.addition')}
          {...offenseLocationHousenumberAdditionProps}
        />
      </SplitContainer>
      {type === 'juridical' && (
        <RoadTypeSelector
          disabled={disabled}
          readOnly={formik.status.readOnly}
          error={formik.errors.offenseLocationRoadType}
          {...formik.getFieldProps('offenseLocationRoadType')}
          onChange={event =>
            formik.setFieldValue('offenseLocationRoadType', parseInt(event.target.value))
          }
        />
      )}
      {type === 'juridical' && (
        <LabelToggle
          disabled={formik.status.readOnly}
          label={I18n.t('activerecord.attributes.ticket.offense_location_within_city_limits')}
          name="offenseLocationWithinCityLimits"
          isOn={offenseLocationWithinCityLimits}
          onChange={formik.handleChange}
        />
      )}
      <LabelTextarea
        readOnly={formik.status.readOnly}
        label={I18n.t('tickets.full_ticket.labels.offense_location_remark')}
        {...formik.getFieldProps('offenseLocationRemark')}
      />
      {locationPresent && (
        <MapModal
          isVisible={mapIsOpen}
          toggleMap={toggleMap}
          street={offenseLocationStreet}
          houseNumber={offenseLocationHousenumber}
          city={offenseLocationCity}
          center={{ lat: offenseLatitude, lng: offenseLongitude }}
        />
      )}
    </StyledCard>
  );
};

export default LocationCard;
