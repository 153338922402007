import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import formatDate from '@/utils/formatDate';
import usePermission from '@/hooks/usePermission';
import Card, { LineBreak } from './components/Card';
// import IconContainer, { Checkmark } from './components/IconContainer';
import InfoText from './components/InfoText';
import Select from './components/LabelSelect';

import { GET_PARTNERS, GET_PROJECTS } from '@/components/queries/tickets';
import DatePicker from './components/LabelDatePicker';
import Creators from './components/Creators';
import { ShortDateFormat } from '@/utils/DateFormats';

const Container = styled(Card)`
  .line-break {
    height: 8px;
  }
`;

const dataToOption = ({ id, displayName }) => ({ id, name: displayName });

const TicketCard = ({ formik, ticketData = {} }) => {
  const { data: partnerData, loading: partnersIsLoading } = useQuery(GET_PARTNERS);
  const { data: projectData, loading: projectsIsLoading } = useQuery(GET_PROJECTS);
  const canSetCreator =
    usePermission('setCreator') && formik.values.type === 'juridical' && !formik.values.id;
  const partnerOptions = partnerData ? partnerData.partners.map(dataToOption) : [];

  const projectOptions = projectData ? projectData.projects : [];

  const { writtenAt, id, type, state } = formik.values;

  const {
    recordingNumber,
    offenseType,
    paymentState,
    receiptNumber,
    creator,
    ticketDecorator
  } = ticketData;

  const { human_source_system: sourceSystem } = ticketDecorator || {};

  return (
    <Container
      title={I18n.t('tickets.form.title.ticket')}
      colorScheme={{ primaryColor: '#975D32', secondaryColor: '#F8F4F2' }}
      // icon={<IconContainer Icon={Checkmark} />}
    >
      {state !== 'complete' && !formik.status.readOnly ? (
        <DatePicker
          label={I18n.t('activerecord.attributes.ticket.written_at')}
          error={formik.errors.writtenAt}
          showTimeInput
          {...formik.getFieldProps('writtenAt')}
          onChange={date => formik.setFieldValue('writtenAt', date.toISOString())}
        />
      ) : (
        <InfoText
          className="flex-start"
          label={I18n.t('activerecord.attributes.ticket.written_at')}
          text={writtenAt ? formatDate(writtenAt, ShortDateFormat) : '-'}
          highlighted
        />
      )}
      <InfoText
        className="flex-start"
        label={I18n.t('tickets.titles.case_number')}
        text={id || '-'}
      />
      <InfoText
        className="flex-start"
        label={I18n.t('tickets.titles.recording_number')}
        text={recordingNumber || '-'}
      />
      {paymentState === 'paid' && (
        <InfoText
          label={I18n.t('tickets.full_ticket.labels.receipt_number')}
          text={receiptNumber || '-'}
        />
      )}
      <div className="line-break" />
      <InfoText
        className="flex-start"
        label={I18n.t('tickets.titles.source_system')}
        text={sourceSystem || '-'}
      />
      {canSetCreator && !formik.status.readOnly ? (
        <Creators
          fieldProps={{
            readOnly: formik.status.readOnly,
            value: formik.getFieldProps('creator').value || creator?.id,
            onChange: e => formik.setFieldValue('creator', e.target.value)
          }}
        />
      ) : creator?.name ? (
        <InfoText
          className="flex-start"
          label={I18n.t('tickets.titles.creator')}
          text={creator?.name}
        ></InfoText>
      ) : (
        <InfoText
          className="flex-start"
          label={I18n.t('tickets.titles.username')}
          text={ticketData.userUsername || '-'}
        ></InfoText>
      )}
      <InfoText
        className="flex-start"
        label={I18n.t('tickets.titles.ticket_type')}
        text={type || '-'}
      />
      <InfoText
        className="flex-start"
        label={I18n.t('tickets.titles.offence_type')}
        text={offenseType || '-'}
      />
      <LineBreak />
      <Select
        disabled={partnersIsLoading}
        readOnly={formik.status.readOnly}
        label={I18n.t('activerecord.attributes.ticket.partner_username')}
        options={partnerOptions}
        placeholder=""
        {...formik.getFieldProps('partnerId')}
      ></Select>
      <Select
        disabled={projectsIsLoading || projectOptions.length === 0}
        readOnly={formik.status.readOnly}
        label={I18n.t('tickets.titles.project')}
        options={projectOptions}
        placeholder=""
        {...formik.getFieldProps('project')}
      ></Select>
    </Container>
  );
};

export default TicketCard;
