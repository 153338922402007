import React from 'react';
import { useHistory } from 'react-router';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import {
  Alert,
  FaCheck,
  FaTimes,
  FaExclamationTriangle,
  FaFilePdf,
  FaLock,
  FaCoins,
  FaFileExport,
  FaFileExcel,
  InfoIcon,
  SortTh,
  Table,
  Loading
} from 'brickyard-ui';

import Tag from '../Ticket/components/Tag';
import formatDate from '@/utils/formatDate';
import { ShortDateFormat } from '@/utils/DateFormats';
const _ = require('lodash');

const headers = [
  {
    label: I18n.t('tickets.titles.pv_number'),
    key: 'pvNumber',
    sortable: true,
    width: '12%'
  },
  {
    label: I18n.t('tickets.titles.case_number'),
    key: 'id',
    sortable: true,
    width: '12%'
  },
  {
    label: I18n.t('activerecord.attributes.ticket.offense_code'),
    key: 'offenseCode',
    sortable: true,
    width: '10%'
  },
  {
    label: I18n.t('tickets.titles.enforcement_configuration'),
    key: 'enforcementConfiguration',
    sortable: false,
    width: '12%'
  },
  {
    label: I18n.t('activerecord.attributes.ticket.vehicle_license_plate_number'),
    key: 'vehicleLicensePlateNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: I18n.t('activerecord.attributes.ticket.suspect_lastname'),
    key: 'suspectLastname',
    sortable: true,
    width: '15%'
  },
  {
    label: I18n.t('tickets.full_ticket.titles.location'),
    key: 'suspectOffenseLocation',
    sortable: false,
    width: '20%'
  },
  {
    label: I18n.t('activerecord.attributes.ticket.written_at'),
    key: 'writtenAt',
    sortable: true,
    width: '10%'
  },
  {
    label: I18n.t('activerecord.attributes.ticket.state'),
    key: 'state',
    sortable: true,
    width: '9%'
  },
  {
    label: (
      <InfoIcon
        Icon={FaExclamationTriangle}
        placement="top"
        text={I18n.t('tickets.titles.warning')}
      />
    ),
    key: 'isWarning',
    sortable: true,
    width: '4%'
  },
  {
    label: (
      <InfoIcon
        Icon={FaFileExport}
        placement="top"
        text={I18n.t('activerecord.attributes.ticket.exported')}
      />
    ),
    key: 'exportState',
    sortable: true,
    width: '3%'
  },
  {
    label: (
      <InfoIcon
        Icon={FaLock}
        placement="top"
        text={I18n.t('activerecord.attributes.ticket.blocked')}
      />
    ),
    key: 'blockState',
    sortable: true,
    width: '3%'
  },
  {
    label: (
      <InfoIcon
        Icon={FaCoins}
        placement="top"
        text={I18n.t('activerecord.attributes.ticket.paid')}
      />
    ),
    key: 'paymentState',
    sortable: true,
    width: '3%'
  },
  {
    label: (
      <InfoIcon
        Icon={FaFileExcel}
        placement="top"
        text={I18n.t('activerecord.attributes.ticket.dismissed')}
      />
    ),
    key: 'dismissalState',
    sortable: true,
    width: '3%'
  }
];

export const RECORD_VIEW = gql`
  mutation RecordView($id: ID!) {
    ticketRecordView(id: $id) {
      id
    }
  }
`;

const TicketsTable = ({ onSort, sorting, tickets, isHistorySearch }) => {
  const history = useHistory();

  const [recordView, { loading: recordViewLoading }] = useMutation(RECORD_VIEW);

  const getComponent = (key, ticket) => {
    const iconKeys = ['exportState', 'paymentState', 'blockState', 'dismissalState'];
    const getIcon = val => {
      if (['exported', 'paid', 'blocked', 'dismissed'].includes(val))
        return (
          <span>
            <FaCheck color="#28A745" size="16" />
          </span>
        );

      return (
        <span>
          <FaTimes color="#E0E0E0" size="16" />
        </span>
      );
    };

    if (iconKeys.includes(key)) {
      return getIcon(ticket[key]);
    }

    if (key === 'isWarning' && ticket.isWarning) {
      return (
        <span>
          <FaExclamationTriangle color="#FFC107" size="16" />
        </span>
      );
    }

    if (key === 'state') {
      const tagProps = ticket[key] === 'complete' ? { color: '0,199,44' } : { color: '247,194,68' };

      return (
        <Tag className={`tag ${isHistorySearch && 'history-tag'}`} {...tagProps}>
          {I18n.t(`activerecord.attributes.ticket.states.${ticket[key]}`).toUpperCase()}
        </Tag>
      );
    }

    return ticket[key];
  };

  const formattedLocation = ticket => {
    const offenseLocation = _.trim(
      _.join([ticket.offenseLocationStreet, ticket.offenseLocationHousenumber], ' ')
    );
    return [offenseLocation, ticket.offenseLocationCity].filter(loc => !_.isEmpty(loc)).join(', ');
  };

  const formattedTickets = () => {
    return tickets.map(t => ({
      ...t,
      writtenAt: formatDate(t.writtenAt, ShortDateFormat),
      suspectOffenseLocation: formattedLocation(t)
    }));
  };

  if (!tickets || !tickets.length)
    return <Alert variant="info">{I18n.t('tickets.messages.no_tickets_found')}</Alert>;

  if (recordViewLoading) {
    return <Loading />;
  }

  return (
    <Table size="sm" className="tickets-table">
      <thead>
        <tr>
          {headers.map((h, idx) =>
            h.sortable ? (
              <SortTh
                key={idx}
                onSort={sort => onSort({ key: h.key, order: sort })}
                sortState={sorting.key === h.key && sorting.order}
                width={h.width}
              >
                <span
                  className="clickable"
                  onClick={() => {
                    onSort({
                      key: h.key,
                      order:
                        sorting.key === h.key ? (sorting.order === 'asc' ? 'desc' : 'asc') : 'asc'
                    });
                  }}
                >
                  {h.label}
                </span>
              </SortTh>
            ) : (
              <th key={idx} width={h.width}>
                {h.label}
              </th>
            )
          )}
          <th width="4%" />
        </tr>
      </thead>

      <tbody>
        {formattedTickets().map(ticket => (
          <React.Fragment key={ticket.id}>
            <tr
              className={`clickable ${isHistorySearch && 'history-search-row'}`}
              onClick={async () => {
                try {
                  await recordView({
                    variables: { id: ticket.id }
                  });
                } finally {
                  history.push(`/tickets/${ticket.id}${window.location.search}`);
                }
              }}
            >
              {headers.map(header => (
                <td key={header.key}>{getComponent(header.key, ticket)}</td>
              ))}
              <td>
                <a
                  href={`/tickets/${ticket.id}/export_to_pdf`}
                  rel="noopener noreferrer"
                  data-method="post"
                  target="_blank"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <InfoIcon
                    Icon={FaFilePdf}
                    text={I18n.t('tickets.actions.export_to_pdf')}
                    placement="top"
                  />
                </a>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default TicketsTable;
