import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { Loading } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import withStoreProvider from '@/utils/withStoreProvider';
import withApolloProvider from '@/utils/withApolloProvider';
import { URLToObject } from '@/utils/URLQueryParser';

import useContinuousPagination from '@/hooks/useContinuousPagination';

import LeftMenu from './LeftMenu';
import C01Map from '../Observations/C01Map';
import ScancarMap from '../Observations/ScancarMap';
import ObservationsContainer from '../Observations/ObservationsContainer';
import ObservationsMapControl from '../Observations/ObservationsMapControl';

import { GET_AREAS } from '@/components/queries/SharedQueries';

import 'styles/scenes/observations.scss';
import { setCurrentObservation, setPreviousBounds } from '@/actions/observationActions';

window.google = null;

const Observations = ({ user, type = 'c01' }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const environmental = params.get('environmental') === 'true';
  const { loading, data, error, called } = useQuery(GET_AREAS, {
    variables: {
      search: {
        type,
        environmental
      },
      limit: 100
    }
  });
  const [map, setMap] = useState(null);
  const [selectedCam, setSelectedCam] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [sortBy, setSortBy] = useState('OBSERVED_AT_ASC');
  const [showMap, setShowMap] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const dispatch = useDispatch();
  const currentObservationId = useSelector(state => state.observations.current);
  const pagination = useContinuousPagination();

  useEffect(() => {
    if (!loading && data && !selectedArea) {
      const urlObject = URLToObject(window.location);
      if (urlObject.areaId && data.areas.length) {
        const area = data.areas.find(a => a.id === urlObject.areaId);
        if (area) {
          setSelectedArea(area);
          return;
        }
      }
      data.areas.length && setSelectedArea(data.areas[0]);
    }
  }, [loading, data]);

  const parsePaths = mapParams => {
    if (!mapParams) {
      return;
    }
    const points = JSON.parse(mapParams);
    return points.map(latLng => ({ lat: latLng[0], lng: latLng[1] }));
  };

  const getCenter = () => {
    if (!selectedArea.mapParameters) {
      if (selectedArea.organisation && selectedArea.organisation.country) {
        return selectedArea.organisation.country.name;
      }
      return null;
    }
    let center = parsePaths(data.areas[0].mapParameters);
    selectedArea && (center = parsePaths(selectedArea.mapParameters)[0]);
    selectedCam && (center = { lat: selectedCam.latitude, lng: selectedCam.longitude });
    return center;
  };

  useEffect(() => {
    if ((type === 'c01' || type === 'scancar') && selectedCam) {
      setSelectedArea(data.areas.find(a => a.cameras.map(c => c.id).includes(selectedCam.id)));
      setMapCenter(getCenter());
      map.setZoom(24);
    }
  }, [selectedCam]);

  const onAreaChange = a => {
    setSelectedArea(a);
    setSelectedCam(null);
  };

  const onObsClick = (obsId, endCursor, previousBounds, mapZoomLevel) => {
    if (endCursor) {
      pagination.next(endCursor);
    } else {
      pagination.start();
    }

    dispatch(setCurrentObservation(obsId));
    dispatch(setPreviousBounds({ bounds: previousBounds, zoom: mapZoomLevel }));
  };

  useEffect(() => {
    if (type === 'c01' && map && selectedArea) {
      if (selectedCam && selectedCam.latitude && selectedCam.longitude) {
        map.setZoom(24);
      } else {
        selectedArea.mapParameters && map.setZoom(12);
        !selectedArea.mapParameters && map.setZoom(6);
      }
    }
  }, [map, mapCenter]);

  useEffect(() => {
    selectedArea && setMapCenter(getCenter());
  }, [selectedArea]);

  if (loading || !called) return <Loading variant="by-primary" />;
  if (error) return <p>{error.toString()}</p>;

  return (
    <div className="app-container">
      <LeftMenu />
      <div id="observations" className={type?.toLowerCase()}>
        {selectedArea ? (
          <>
            {!currentObservationId && (
              <>
                <ObservationsMapControl
                  areas={data.areas}
                  user={user}
                  type={type}
                  selectedArea={selectedArea}
                  selectedCam={selectedCam}
                  showMap={showMap}
                  onCamChange={setSelectedCam}
                  toggleMap={() => setShowMap(!showMap)}
                  onAreaChange={onAreaChange}
                />
                {showMap &&
                  (type === 'c01' ? (
                    <C01Map
                      center={mapCenter}
                      areas={data.areas}
                      onLoad={setMap}
                      onAreaClick={onAreaChange}
                      onCamClick={setSelectedCam}
                      parsePaths={parsePaths}
                    />
                  ) : (
                    <ScancarMap
                      center={mapCenter}
                      area={selectedArea}
                      onLoad={setMap}
                      sortBy={sortBy}
                      onObsClick={onObsClick}
                    />
                  ))}
              </>
            )}
            <ObservationsContainer
              environmental={environmental}
              area={selectedArea}
              camera={selectedCam}
              unselectCam={() => setSelectedCam(null)}
              showMap={showMap}
              pagination={pagination}
              type={type}
              updateMap={setSortBy}
            />
          </>
        ) : (
          <p className="empty-message">No areas available</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default withStoreProvider(withApolloProvider(connect(mapStateToProps)(Observations)));
