import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { AutoComplete, Form as BYForm, InfoIcon } from 'brickyard-ui';
import { GET_OFFENSES } from '@/components/queries/SharedQueries';

const OffenseSelector = ({
  onChange,
  onOffense,
  name,
  value,
  size,
  disabled,
  readOnly,
  offenseCategory,
  initialOffense,
  isInvalid,
  showDescription,
  showLabel = true,
  type
}) => {
  const [offense, setOffense] = useState(initialOffense);
  const [search, setSearch] = useState(value || '');
  const { loading, data } = useQuery(GET_OFFENSES, {
    variables: { code: search, type: type || null },
    onCompleted: () => {
      if (!!value && search === value) {
        setOffense(data.offenses?.[0]);
      }
    }
  });

  const getOffenseList = () => {
    if (disabled || readOnly || loading || !data) return [];
    return data.offenses.map(o => ({
      ...o,
      label: `${o.offenseCode} ${
        showDescription
          ? `- ${o.text}`
          : ` - ${o.offenseCategories
              .filter(oc => oc.categoryGroup == o.offenseCategoryGroup)
              .map(oc => oc.description)}`
      }`
    }));
  };

  useEffect(() => {
    setSearch(value || '');
  }, [value, disabled]);

  useEffect(() => {
    if (!search) {
      setOffense(null);
      onChange({ target: { name, value: '' } });
    }
  }, [search]);

  useEffect(() => {
    if (offense?.offenseCode) {
      setSearch(offense.offenseCode);
      onChange({ target: { name, value: offense.offenseCode } });
    }
    !!onOffense && onOffense(offense);
  }, [offense]);

  return (
    <div className="d-flex">
      <BYForm.Group
        className={`flex-fill offense-code ${initialOffense ? '' : 'offense-code-alert'}`}
      >
        {showLabel && (
          <BYForm.Label>{I18n.t('observations.observations.details.offense_code')}</BYForm.Label>
        )}
        <AutoComplete
          inputProps={{
            className: 'offenseSelector',
            value: search,
            autoComplete: 'off',
            onChange: evt => setSearch(evt.target.value),
            disabled,
            readOnly,
            size,
            name,
            isInvalid
          }}
          onSelect={setOffense}
          loading={loading}
          list={getOffenseList()}
        />
        {offenseCategory && (
          <BYForm.Text muted>
            ({I18n.t('observations.observations.details.offense_category')}:{offenseCategory})
          </BYForm.Text>
        )}
        {showDescription && <BYForm.Text muted>{offense?.text}</BYForm.Text>}
      </BYForm.Group>

      {!showDescription && (
        <InfoIcon
          text={offense?.text || I18n.t('observations.observations.details.no_offense_detail')}
          variant="by-dark"
          placement="top"
        />
      )}
    </div>
  );
};

OffenseSelector.defaultProps = {
  size: 'md',
  disabled: false,
  readOnly: false
};

export default OffenseSelector;
