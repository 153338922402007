import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_CREATORS } from '@/components/queries/tickets';
import Select from './LabelSelect';

const dataToOption = ({ id, displayName }) => ({ id, name: displayName });

const Creators = ({ fieldProps }) => {
  const { data, loading } = useQuery(GET_CREATORS);

  const creatorOptions = data ? data.creators.map(dataToOption) : [];

  return (
    <Select
      disabled={loading}
      label={I18n.t('tickets.titles.creator')}
      options={creatorOptions}
      placeholder=""
      {...fieldProps}
    ></Select>
  );
};

export default Creators;
