import * as Yup from 'yup';

const IPV4_REGEX = /(^(\d{1,3}\.){3}(\d{1,3})$)/;
const MAC_ADDRESS_REGEX = /^([0-9a-fA-F]{2}[.:-]{0,1}){6}$/;
const LATITUDE_REGEX = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
const LONGITUDE_REGEX = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

function ipv4(message = 'Invalid IP address') {
  return this.matches(IPV4_REGEX, {
    message,
    excludeEmptyString: true
  }).test('ip', message, value => {
    return value === undefined || value === null || value.trim() === ''
      ? true
      : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
  });
}

Yup.addMethod(Yup.string, 'ipv4', ipv4);

const fixedCameraSchema = Yup.object({
  protocol: Yup.string()
    .oneOf(['anpr_xml', 'anpr_json', 'railway_json', 'bop'])
    .required(I18n.t('settings.field_protocol_required')),
  name: Yup.string().required(I18n.t('settings.field_name_required')),
  status: Yup.string()
    .oneOf(['enabled', 'disabled'])
    .required(I18n.t('settings.field_status_required')),
  tiny_system_states_message_active: Yup.boolean().nullable(),
  feeds_whitelist: Yup.boolean().nullable(),

  ip_addr: Yup.string().when('protocol', {
    is: protocol => {
      return protocol === 'anpr_xml';
    },
    then: Yup.string()
      .required(I18n.t('settings.field_ip_addr_required'))
      .ipv4(I18n.t('settings.field_ip_format')),
    otherwise: Yup.string().nullable()
  }),
  port: Yup.number().when('protocol', {
    is: protocol => {
      return protocol === 'anpr_xml';
    },
    then: Yup.number()
      .required(I18n.t('settings.field_port_required'))
      .typeError(I18n.t('settings.field_port_number')),
    otherwise: Yup.number().nullable()
  }),
  mac_address: Yup.string().when('protocol', {
    is: protocol => {
      return protocol === 'anpr_json' || protocol === 'railway_json';
    },
    then: Yup.string()
      .required(I18n.t('settings.field_mac_address_required'))
      .matches(MAC_ADDRESS_REGEX, I18n.t('settings.field_mac_format')),
    otherwise: Yup.string().nullable()
  }),

  latitude: Yup.string()
    .required(I18n.t('settings.field_latitude_required'))
    .matches(LATITUDE_REGEX, I18n.t('settings.field_latitude_format')),
  longitude: Yup.string()
    .required(I18n.t('settings.field_longitude_required'))
    .matches(LONGITUDE_REGEX, I18n.t('settings.field_longitude_format')),
  road_type_id: Yup.number().required(I18n.t('settings.field_road_type_id_required')),
  within_city_limits: Yup.string().required(I18n.t('settings.field_within_city_limits_required')),
  street: Yup.string().required(I18n.t('settings.field_street_required')),
  city: Yup.string().required(I18n.t('settings.field_city_required')),
  municipality: Yup.string().nullable(),
  railway_speed_limit: Yup.string().when('protocol', {
    is: protocol => {
      return protocol === 'railway_json';
    },
    then: Yup.string()
      .oneOf(['30', '50', '60', '70', '80'])
      .required(I18n.t('settings.field_railway_speed_limit_required')),
    otherwise: Yup.string().nullable()
  }),
  railway_moped_on_main_road: Yup.boolean().nullable(),
  overview_image: Yup.mixed()
    .nullable()
    .test('FILE_FORMAT', I18n.t('settings.overview_image_format'), val => {
      return val?.type ? SUPPORTED_IMAGE_FORMATS.includes(val.type) : true;
    })
});

export default fixedCameraSchema;
