import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loading } from 'brickyard-ui';
import LeftMenu from '../../../../LeftMenu';
import ExemptionRequestDetails from './components/ExemptionRequestDetails';
import ExemptionRequestsTable from './components/ExemptionRequestsTable';
import { selectExemptionRequestsState, useExemptionRequestsSlice } from './slice';

const StyledExemptionRequests = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;

  .page-content {
    display: flex;
    width: calc(100% - 80px);
    height: 100%;
    flex-direction: row;
    background-color: #f6f8fb;
  }

  .loading-box {
    display: flex;
    margin: auto;
  }
`;

const ExemptionRequests = () => {
  const { actions } = useExemptionRequestsSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchExemptionRequests());
    dispatch(actions.fetchExemptionTypes());
  }, [dispatch]);

  const state = useSelector(selectExemptionRequestsState);

  const onRowClick = exemptionRequestId => {
    dispatch(actions.fetchExemptionRequest(exemptionRequestId));
  };

  const onFormSubmit = values => {
    dispatch(actions.mutateExemptionRequest(values));
  };

  return (
    <StyledExemptionRequests>
      <LeftMenu copExemptionRequestsVisible={true} />
      <div className="page-content">
        <ExemptionRequestsTable exemptionRequests={state.exemptionRequests} onClick={onRowClick} />

        {state.exemptionRequests.length !== 0 &&
          (state.selectedExemptionRequest && !state.loading ? (
            <ExemptionRequestDetails
              exemptionRequest={state.selectedExemptionRequest}
              exemptionTypes={state.exemptionTypes}
              onFormSubmit={onFormSubmit}
            />
          ) : (
            <div className="loading-box">
              <Loading />
            </div>
          ))}
      </div>
    </StyledExemptionRequests>
  );
};

export default ExemptionRequests;
