import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

import { Loading } from 'brickyard-ui';

import usePermission from '@/hooks/usePermission';

import withApolloProvider from '@/utils/withApolloProvider';

import useTicketsForm from '@/hooks/useTicketsForm';

import Danger from '@/assets/img/danger.svg';

import { GET_DETAILED_TICKET, GET_TICKET_PERMISSIONS } from '@/components/queries/tickets';

import 'styles/scenes/tickets-full.scss';
import TicketCard from './Cards/TicketCard';
import OffenseCard from './Cards/OffenseCard';
import VehicleCard from './Cards/VehicleCard';
import LocationCard from './Cards/LocationCard';
import SuspectCard from './Cards/SuspectCard';
import StatementCard from './Cards/StatementCard';
import MiscCard from './Cards/MiscCard';
import Actions from './Cards/Actions';
import Button from './components/Button';
import Tags from './Tags';
import { FETCH_PERMISSIONS_SUCCESS } from '../../../actions/actionTypes';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1410px;
  min-height: 900px;
  height: calc(100vh - 40px);

  > .flex-between {
    > .flex-start,
    > .flex-end {
      align-items: center;
    }

    > .flex-start {
      padding: 16px 0 16px 19px;

      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 400;
      }

      img {
        margin: 0 28px 0 10px;
      }
    }

    > .flex-end {
      padding-right: 19px;

      button,
      a.button {
        margin: 0 4px;
        color: #007bff;
        border: 1px solid #007bff;
      }

      img.close {
        padding-left: 15px;
      }
    }
  }

  > .flex-center {
    height: calc(100% - 60px);
  }

  .submit {
    padding: 20px 10px;
  }
`;

const getHeight = props => {
  if (props.fill === 'full') {
    return '100%';
  }

  if (props.fill === 'half') {
    return '50%';
  }

  return props.fill;
};

const Column = styled.div`
  width: 282px;
  height: 100%;
  overflow: hidden;
  flex-direction: column;

  > article {
    overflow-y: auto;
    margin: 6px;
    height: calc(${getHeight});
  }
`;

const Ticket = ({ readOnly = false }) => {
  const { id: ticketId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const ticketType = params.get('type') || undefined;
  const canEdit = usePermission('edit');
  const canCreate = usePermission('create');
  const { data: ticketData, loading } = useQuery(GET_DETAILED_TICKET, {
    variables: { id: ticketId },
    skip: !ticketId
  });

  const [formik] = useTicketsForm(
    ticketType,
    ticketData?.tickets.nodes[0] || undefined,
    () =>
      (window.location.href =
        ticketData?.tickets.nodes[0] === undefined
          ? '/tickets/?s=WRITTEN_AT_DESC'
          : `/tickets/${window.location.search}`)
  );

  const { data: permissionData, loading: loadingPermissions } = useQuery(GET_TICKET_PERMISSIONS, {
    variables: {
      id: ticketId,
      params: { type: formik.values.type, is_warning: ticketData?.tickets.nodes[0].isWarning }
    }
  });

  useEffect(() => {
    if (permissionData && !loadingPermissions) {
      dispatch({
        type: FETCH_PERMISSIONS_SUCCESS,
        payload: permissionData.ticketPermission
      });
    }
  }, [permissionData, loadingPermissions]);

  useEffect(() => {
    formik.setStatus({ ...formik.status, readOnly: readOnly });
  }, [readOnly]);

  const getTitle = () => {
    return formik.values.id
      ? `${I18n.t(
          formik.status.readOnly ? 'tickets.form.title.ticket' : 'tickets.edit.title.edit_ticket'
        )} ${ticketData.tickets.nodes[0].pvNumber}`
      : I18n.t('tickets.new.title.new_ticket');
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <section className="flex-between">
        <div className="flex-start">
          <h1>{getTitle()}</h1>
          {ticketData?.tickets.nodes[0].isWarning && <img alt="Danger" src={Danger} />}
          {ticketData?.tickets.nodes[0] && <Tags ticket={ticketData.tickets.nodes[0]} />}
        </div>
        <Actions ticket={ticketData?.tickets.nodes[0]} />
      </section>
      <div className="flex-center">
        <Column className="flex-between" fill="half">
          <TicketCard formik={formik} ticketData={ticketData?.tickets.nodes[0]} />
          <OffenseCard formik={formik} ticketData={ticketData?.tickets.nodes[0]} />
        </Column>
        <Column className="flex-between" fill="half">
          <VehicleCard formik={formik} />
          <LocationCard formik={formik} />
        </Column>
        <Column className="flex-between" fill="full">
          <SuspectCard formik={formik} />
        </Column>
        <Column className="flex-between" fill="full">
          <StatementCard formik={formik} />
        </Column>
        <Column className="flex-between" fill="100% - 113px">
          <MiscCard formik={formik} />
          <div className="submit flex-evenly">
            {formik.status.readOnly
              ? canEdit && (
                  <Button
                    onClick={() =>
                      history.push(
                        `/tickets/${ticketData?.tickets.nodes[0].id}/edit${window.location.search}`
                      )
                    }
                  >
                    {I18n.t('tickets.edit.title.edit_ticket')}
                  </Button>
                )
              : canCreate && (
                  <>
                    {(!ticketId || ticketData?.tickets.nodes[0].state !== 'complete') && (
                      <Button onClick={formik.bypassValidation}>
                        {I18n.t('tickets.form.buttons.store_ticket')}
                      </Button>
                    )}
                    <Button
                      disabled={Object.keys(formik.errors).length !== 0}
                      onClick={formik.handleSubmit}
                    >
                      {I18n.t('tickets.form.buttons.finish_ticket')}
                    </Button>
                  </>
                )}
          </div>
        </Column>
      </div>
    </Container>
  );
};

export const useEnforcementCities = 'enforcement';
export const useMunicipalitiesCities = 'municipalities';

export default withApolloProvider(Ticket);
