import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Marker, MarkerClusterer } from '@react-google-maps/api';

import Map from '@shared/maps/Map';
import CameraOn from '@/assets/img/camera_on.svg';

import { GET_OBSERVATIONS_FOR_MAP } from '@/components/queries/observations';

import { useSelector } from 'react-redux';

const ScancarMap = ({ center, area, onLoad, sortBy, onObsClick }) => {
  const [map, setMap] = useState(null);
  const previousBounds = useSelector(state => state.observations.previousBounds ?? null);
  const filters = useSelector(state => state.observations.filters);
  const activeFilters = () => {
    return (
      !filters ||
      Object.keys(filters).reduce(
        (removed, key) => (filters[key] ? { ...removed, [key]: filters[key] } : removed),
        {}
      )
    );
  };

  const { loading, data, error } = useQuery(GET_OBSERVATIONS_FOR_MAP, {
    variables: {
      first: 1000,
      search: {
        ...activeFilters(),
        areaId: area.id,
        s: sortBy
      }
    }
  });

  if (error) {
    console.error(error);
    return null;
  }

  const onClick = edge => {
    const index = data.observations.edges.findIndex(e => e.node.id === edge.node.id);
    const previousCursor = index <= 0 ? null : data.observations.edges[index - 1].cursor;
    const clickedId = data.observations.edges[index].node.id;

    onObsClick(clickedId, previousCursor, map.getBounds(), map.zoom);
  };

  const edges = loading
    ? []
    : data.observations.edges.filter(
        edge => !!edge.node.vehicleLatitude && !!edge.node.vehicleLongitude
      );

  useEffect(() => {
    if (map && edges.length) {
      const bounds = new window.google.maps.LatLngBounds();
      edges.forEach(edge => {
        bounds.extend(
          new window.google.maps.LatLng(edge.node.vehicleLatitude, edge.node.vehicleLongitude)
        );
      });

      if (previousBounds) {
        map.fitBounds(previousBounds?.bounds);
        map.setZoom(previousBounds?.zoom);
      } else {
        map.fitBounds(bounds);
      }
    }
  }, [map, edges, previousBounds]);

  return (
    <Map
      center={center}
      onLoad={map => {
        setMap(map);
        onLoad(map);
      }}
      zoomLevel={13}
    >
      <MarkerClusterer zoomOnClick minimumClusterSize={2} maxZoom={16} clusterClass="cluster">
        {clusterer =>
          edges.map(edge => (
            <Marker
              key={edge.node.id}
              position={{ lat: edge.node.vehicleLatitude, lng: edge.node.vehicleLongitude }}
              t
              onClick={() => onClick(edge)}
              clusterer={clusterer}
              icon={CameraOn}
            />
          ))
        }
      </MarkerClusterer>
    </Map>
  );
};

export default ScancarMap;
