import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Form as BYForm, Modal, MessagePopup } from 'brickyard-ui';

import usePermission from '@/hooks/usePermission';

import Button from '../../components/Button';

export const PAY_TICKET = gql`
  mutation PayTicket($id: ID!, $receiptNumber: String!) {
    ticketPay(id: $id, receiptNumber: $receiptNumber) {
      id
      paymentState
      receiptNumber
    }
  }
`;

export const UNPAY_TICKET = gql`
  mutation UnpayTicket($id: ID!) {
    ticketUnpay(id: $id) {
      id
      paymentState
      receiptNumber
    }
  }
`;

const TogglePay = ({ ticket }) => {
  const { id, paymentState } = ticket;
  const paid = paymentState === 'paid';
  const [receiptNumber, setReceiptNumber] = useState('');
  const [showReceipt, setShowReceipt] = useState(false);
  const canPay = usePermission('pay');
  const canUnpay = usePermission('unpay');
  const [pay, { loading: payLoading, error: payErr }] = useMutation(PAY_TICKET, {
    variables: { id, receiptNumber },
    onCompleted: () => {
      setReceiptNumber('');
      setShowReceipt(false);
    }
  });
  const [unpay, { loading: unpayLoading, error: unpayErr }] = useMutation(UNPAY_TICKET, {
    variables: { id }
  });
  const [err, setErr] = useState('');

  useEffect(() => {
    if (payErr || unpayErr) {
      setErr(payErr?.message || unpayErr?.message);
    }
  }, [payErr, unpayErr]);

  const toggle = () => (paid ? unpay() : setShowReceipt(true));

  if ((!canUnpay && paid) || (!canPay && !paid)) {
    return null;
  }

  return (
    <div>
      <Button onClick={toggle} disabled={payLoading || unpayLoading}>
        {paid ? I18n.t('tickets.actions.unpay') : I18n.t('tickets.actions.pay')}
      </Button>

      <MessagePopup
        type="error"
        show={err}
        onHide={() => setErr('')}
        okBtn={{ label: I18n.t('actions.close'), onClick: () => setErr('') }}
        message={err}
      />

      <Modal show={showReceipt} onHide={() => setShowReceipt(false)}>
        <Modal.Header>
          <Modal.Title>{I18n.t('tickets.dialogs.pay.description')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('tickets.full_ticket.labels.receipt_number')}</BYForm.Label>
            <BYForm.Control
              name="receiptNumber"
              value={receiptNumber}
              onChange={evt => setReceiptNumber(evt.target.value)}
            />
          </BYForm.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="by-dark"
            className="float-right ml-2"
            onClick={() => setShowReceipt(false)}
          >
            {I18n.t('actions.cancel')}
          </Button>

          <Button
            variant="by-primary"
            className="float-right"
            onClick={pay}
            disabled={!receiptNumber}
          >
            {I18n.t('actions.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TogglePay;
