import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useFormik } from 'formik';

import { Modal, Button, Container, Col, Form as BYForm, Row } from 'brickyard-ui';

import { GET_PROJECTS } from '@/components/queries/tickets';

import RecentlyUsedFilters from '@shared/searches/RecentlyUsedFilters';

export const TICKET_RECENTLY_FILTERS = 'TicketsRecentFilters';

const switchFields = ['offenseIsTowableEq', 'isWarningEq'];

const switchFilter = revert => field => switchFields.includes(field) === revert;

export const i18nMap = {
  idEq: I18n.t('activerecord.attributes.ticket.id'),
  recordingNumberCont: I18n.t('activerecord.attributes.ticket.recording_number'),
  projectCont: I18n.t('activerecord.attributes.ticket.project'),
  offenseCodeCont: I18n.t('activerecord.attributes.ticket.offense_code'),
  offenseTypeEq: I18n.t('activerecord.attributes.ticket.offense_type'),
  userUsernameCont: I18n.t('activerecord.attributes.ticket.user_username'),
  partnerUsernameCont: I18n.t('activerecord.attributes.ticket.partner_username'),
  enforcementConfigurationIdEq: I18n.t(
    'activerecord.attributes.ticket.enforcement_configuration_id'
  ),
  offenseLocationStreetCont: I18n.t('activerecord.attributes.ticket.offense_location_street'),
  offenseLocationCityCont: I18n.t('activerecord.attributes.ticket.offense_location_city'),
  offenseIsTowableEq: I18n.t('activerecord.attributes.ticket.offense_is_towable'),
  isWarningEq: I18n.t('activerecord.attributes.ticket.is_warning'),
  sourceSystemEq: I18n.t('activerecord.attributes.ticket.source_system'),
  vehicleTypeInsensitiveEqInsensitive: I18n.t('activerecord.attributes.ticket.vehicle_type')
};

const SearchTicketDialog = ({
  show,
  onHide,
  onFiltersChange,
  search,
  offenseTypes,
  licensedSourceSystems,
  enforcementConfigurations
}) => {
  const [recentFilters, setRecentFilters] = useState([]);
  const { data: projectData } = useQuery(GET_PROJECTS, {
    skip: !show
  });

  const getInitialValues = search => {
    return Object.fromEntries(Object.entries(search).filter(([k, _]) => !!i18nMap[k]));
  };

  const dialogFilterValues = values => {
    return Object.fromEntries(Object.entries(values).filter(([k, _]) => !!i18nMap[k]));
  };

  const onSubmit = values => {
    const parsedValues = { ...values };
    Object.keys(parsedValues).forEach(k => {
      formik.initialValues[k] === formik.values[k] && !parsedValues[k] && delete parsedValues[k];
      // This is the only key where backend expects certain values or absence of the key
      if (k === 'offenseTypeEq' && !parsedValues[k]) parsedValues[k] = null;
    });
    onFiltersChange(parsedValues);
    saveRecentFilters(dialogFilterValues(parsedValues));
    onHide();
  };

  const clearFormState = {
    idEq: '',
    projectCont: '',
    recordingNumberCont: '',
    offenseCodeCont: '',
    offenseTypeEq: '',
    userUsernameCont: '',
    partnerUsernameCont: '',
    offenseLocationStreetCont: '',
    offenseLocationCityCont: '',
    enforcementConfigurationIdEq: '',
    sourceSystemEq: '',
    offenseIsTowableEq: false,
    isWarningEq: false
  };

  const formik = useFormik({
    initialValues: {
      ...clearFormState,
      ...getInitialValues(search)
    },
    onSubmit
  });

  useEffect(() => {
    setRecentFilters(JSON.parse(localStorage.getItem(TICKET_RECENTLY_FILTERS)) || []);
  }, [show]);

  useEffect(() => {
    !!search && formik.setValues({ ...formik.values, ...search });
  }, [search]);

  // Get user friendly field values when label and values differ
  const getValueLabel = (field, value) => {
    switch (field) {
      case 'offenseTypeEq':
        return offenseTypes[value];
      case 'sourceSystemEq':
        return licensedSourceSystems?.find(sys => sys.value === value)?.name || value;
      case 'enforcementConfigurationIdEq':
        return enforcementConfigurations?.find(cfg => cfg.id === value)?.name || value;
      default:
        return null;
    }
  };

  const saveRecentFilters = values => {
    const filters = Object.keys(values)
      .filter(field => !!values[field])
      .map(field => ({
        key: field,
        labelI18n: i18nMap[field],
        value: values[field],
        valueLabel: getValueLabel(field, values[field])
      }));
    localStorage.setItem(TICKET_RECENTLY_FILTERS, JSON.stringify(filters));
  };

  const getFieldComponent = field => {
    const specialFields = {
      sourceSystemEq: (
        <BYForm.Control as="select" {...formik.getFieldProps(field)}>
          <option value="" />
          {licensedSourceSystems.map(l => (
            <option key={l.value} value={l.value}>
              {l.name}
            </option>
          ))}
        </BYForm.Control>
      ),
      enforcementConfigurationIdEq: (
        <BYForm.Control as="select" {...formik.getFieldProps(field)}>
          <option value="" />
          {enforcementConfigurations.map(c => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </BYForm.Control>
      ),
      projectCont: (
        <BYForm.Control as="select" {...formik.getFieldProps(field)}>
          <option value="" />
          {projectData?.projects.map(p => (
            <option key={p.id} value={p.name}>
              {p.name}
            </option>
          ))}
        </BYForm.Control>
      ),
      offenseTypeEq: (
        <BYForm.Control as="select" {...formik.getFieldProps(field)}>
          <option value="" />
          {Object.keys(offenseTypes).map(k => (
            <option key={k} value={k}>
              {offenseTypes[k]}
            </option>
          ))}
        </BYForm.Control>
      )
    };

    return specialFields[field] || <BYForm.Control {...formik.getFieldProps(field)} />;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>{I18n.t('tickets.index.tickets')}</Modal.Header>

      <Modal.Body>
        <Container fluid>
          <BYForm>
            <Row>
              {Object.keys(formik.initialValues)
                .filter(switchFilter(false))
                .map(field => (
                  <Col xs="6" key={field}>
                    <BYForm.Group>
                      <BYForm.Label>{i18nMap[field]}</BYForm.Label>
                      {getFieldComponent(field)}
                    </BYForm.Group>
                  </Col>
                ))}
            </Row>
            <Row>
              {Object.keys(formik.initialValues)
                .filter(switchFilter(true))
                .map(field => (
                  <Col xs="3" key={field}>
                    <BYForm.Group>
                      <BYForm.Switch
                        label={i18nMap[field]}
                        id={field}
                        checked={formik.getFieldProps(field).value}
                        {...formik.getFieldProps(field)}
                      />
                    </BYForm.Group>
                  </Col>
                ))}
            </Row>
          </BYForm>

          {!!recentFilters?.length && (
            <RecentlyUsedFilters
              filters={recentFilters}
              onSelectFilter={f => formik.setFieldValue(f.key, f.value)}
            />
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row>
            <Col xs="6">
              <Button
                variant="danger"
                type="button"
                onClick={() => formik.setValues(clearFormState)}
              >
                {I18n.t('observations.observations.search.reset')}
              </Button>
            </Col>
            <Col />
            <Col xs="2">
              <span className="right-buttons">
                <span className="class-with-margin">
                  <Button variant="by-dark" onClick={onHide} block>
                    {I18n.t('actions.cancel')}
                  </Button>
                </span>
              </span>
            </Col>

            <Col xs="2">
              <Button variant="by-primary" type="submit" onClick={formik.handleSubmit} block>
                {I18n.t('actions.search')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

SearchTicketDialog.defaultProps = {
  onFiltersChange: () => {}
};

export default SearchTicketDialog;
