import React, { useState } from 'react';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { useFormik } from 'formik';
import { Button, Form as BYForm, Modal, Alert, Loading } from 'brickyard-ui';
const _ = require('lodash');

import { GET_PERMIT_TYPES } from '@/components/queries/SharedQueries';

export const REQUEST_EXEMPTION = gql`
  mutation RequestExemption(
    $observationId: ID!
    $licensePlate: String!
    $permitTypeId: ID!
    $comment: String
  ) {
    requestExemption(
      observationId: $observationId
      permitTypeId: $permitTypeId
      comment: $comment
      licensePlate: $licensePlate
    ) {
      id
    }
  }
`;

const RequestExemptionButton = ({ observation, disabled = false, variant = 'by-secondary' }) => {
  const [requestExemption, { loading, error }] = useMutation(REQUEST_EXEMPTION, {
    onCompleted: () => setShowModal(false),
    onError: () => {
      setShowModal(true);
    }
  });
  const { data: permitTypeData, loading: permitTypeLoading } = useQuery(GET_PERMIT_TYPES);
  const [showModal, setShowModal] = useState(false);

  const onSubmit = values => {
    if (!_.isEmpty(permitTypeData.permitTypes)) {
      values.permitTypeId = parseInt(permitTypeData.permitTypes.find(p => !p.temporary).id);
    }
    requestExemption({ variables: values });
    setShowModal(false);
  };

  const formik = useFormik({
    initialValues: {
      observationId: observation.id,
      licensePlate: observation.vehicleLicensePlateNumber,
      permitTypeId: null,
      comment: ''
    },
    validationSchema: Yup.object().shape({
      licensePlate: Yup.string().required()
    }),
    onSubmit
  });

  return (
    <>
      <Button
        variant={variant}
        size="sm"
        onClick={() => setShowModal(true)}
        disabled={disabled || permitTypeLoading}
      >
        {I18n.t('observations.observations.details.actions.request_exemption')}
      </Button>

      <Modal show={showModal} onHide={() => !loading && setShowModal(false)} centered>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>{I18n.t('exemption_requests.exemption_request')}</Modal.Header>

          <Modal.Body>
            {error && <Alert variant="danger">{error.toString()}</Alert>}
            <BYForm.Group>
              <BYForm.Label>{I18n.t('observations.observations.list.license_plate')}</BYForm.Label>

              <BYForm.Control {...formik.getFieldProps('licensePlate')} />
              {formik.errors.licensePlate && (
                <BYForm.Control.Feedback>{formik.errors.licensePlate}</BYForm.Control.Feedback>
              )}
            </BYForm.Group>

            <BYForm.Group>
              <BYForm.Label>
                {I18n.t('observations.observations.exemption_dialog.comment')}
              </BYForm.Label>
              <BYForm.Control as="textarea" rows="3" {...formik.getFieldProps('comment')} />
            </BYForm.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="by-dark"
              onClick={() => setShowModal(false)}
              disabled={loading}
            >
              {I18n.t('actions.cancel')}
            </Button>

            <Button size="sm" variant="by-primary" type="submit" disabled={loading}>
              {I18n.t('observations.observations.details.actions.request_exemption')}
              {loading && <Loading size="sm" />}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default RequestExemptionButton;
