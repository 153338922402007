import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { Loading } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';

import withStoreProvider from '@/utils/withStoreProvider';
import withApolloProvider from '@/utils/withApolloProvider';
import { URLToObject } from '@/utils/URLQueryParser';

import useContinuousPagination from '@/hooks/useContinuousPagination';

import LeftMenu from './LeftMenu';
import UseCaseResultsContainer from '../Cop/UseCaseResultsContainer';
import ObservationsMapControl from '../Cop/ObservationsMapControl';

import { GET_USE_CASES, GET_SUSPECT_LISTS } from '@/components/queries/SharedQueries';

import 'styles/scenes/observations.scss';
import { setCurrentObservation, setPreviousBounds } from '@/actions/observationActions';
import { OBSERVATION_OBSERVED_AT_ASC } from '@/utils/consts';
import UseCaseResultsMap from '../Cop/UseCaseResultsMap';

window.google = null;

const CopUseCaseResults = ({ user, type = 'c01' }) => {
  const { loading, data, error, called } = useQuery(GET_USE_CASES, {
    variables: {
      copPermissionBasedScope: true,
      limit: 100
    },
    fetchPolicy: 'no-cache'
  });

  const {
    data: suspectListData,
    loading: suspectListLoading,
    error: suspectListError,
    called: suspectListCalled
  } = useQuery(GET_SUSPECT_LISTS);
  const [map, setMap] = useState(null);
  const [selectedCam, setSelectedCam] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [selectedSuspectList, setSelectedSuspectList] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [sortBy, setSortBy] = useState(OBSERVATION_OBSERVED_AT_ASC);
  const [showMap, setShowMap] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const dispatch = useDispatch();
  const currentObservationId = useSelector(state => state.observations.current);
  const pagination = useContinuousPagination();
  const _ = require('lodash');

  useEffect(() => {
    if (!loading && data && !selectedUseCase) {
      const urlObject = URLToObject(window.location);
      if (urlObject.useCaseId && data.useCases.length) {
        const useCase = data.useCases.find(u => u.id === urlObject.useCaseId);
        if (useCase) {
          setSelectedUseCase(useCase);
          return;
        }
      }
      data.useCases.length && setSelectedUseCase(data.useCases[0]);
    }
  }, [loading, data]);

  const resetMap = () => {
    setMapCenter(getCenter());
    map?.setZoom(8);
  };

  useEffect(() => {
    setSelectedCam(null);
    if (selectedLocation == null) {
      resetMap();
    } else {
      const urlObject = URLToObject(window.location);
      if (urlObject.useCaseId && data.useCases.length) {
        const useCase = data.useCases.find(u => u.id === urlObject.useCaseId);

        if (useCase) {
          const cameras = useCase.cameras.filter(cam => cam.location == selectedLocation?.id);

          if (cameras.length) {
            const bounds = new window.google.maps.LatLngBounds();

            cameras.forEach(cam => {
              bounds.extend({ lat: parseFloat(cam.latitude), lng: parseFloat(cam.longitude) });
            });
            !bounds.isEmpty() && map.fitBounds(bounds);
            if (cameras.length == 1) {
              map?.setZoom(14);
            }
          } else {
            resetMap();
          }
        }
      }
    }
  }, [selectedLocation]);

  const getCenter = () => {
    // TODO: COP this needs to be fully implemented, becuase basing the center
    // only on the organisation country may have unexpected results
    // example: "Nederland" defaults to the city in Colorado, USA, instead of the Netherlands
    // returning null for now will position the map based on the markers
    // Temporary fix: return the center of the Netherlands
    return { lat: 52.1611973294726, lng: 5.621868260475858 };
  };

  useEffect(() => {
    if (map && selectedCam) {
      if (selectedCam.latitude && selectedCam.longitude) {
        if (selectedCam.type !== 'Cop::MobileCamera') {
          setMapCenter({ lat: selectedCam.latitude, lng: selectedCam.longitude });
          map.setZoom(18);
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(new window.google.maps.LatLng(selectedCam.latitude, selectedCam.longitude));
          dispatch(setPreviousBounds({ bounds: bounds, zoom: map.zoom }));
        } else {
          dispatch(setPreviousBounds(null));
        }
      }
    }
  }, [map, selectedCam, showMap]);

  const onUseCaseChange = u => {
    setSelectedSuspectList(null);
    setSelectedUseCase(u);
    setSelectedCam(null);
    setSelectedLocation(null);
  };

  const onSuspectListChange = l => {
    const firstUseCase = l
      ? data.useCases.filter(u =>
          _.find(l.useCases, function(s) {
            return s.id == u.id;
          })
        )[0]
      : data.useCases[0];

    setSelectedSuspectList(l);
    setSelectedUseCase(firstUseCase);
    setSelectedCam(null);
    setSelectedLocation(null);
  };

  const onObsClick = (obsId, endCursor, previousBounds, mapZoomLevel) => {
    if (endCursor) {
      pagination.next(endCursor);
    } else {
      pagination.start();
    }

    dispatch(setCurrentObservation(obsId));
    dispatch(setPreviousBounds({ bounds: previousBounds, zoom: mapZoomLevel }));
  };

  useEffect(() => {
    if (map && selectedUseCase) {
      setMapCenter(getCenter());
      map.setZoom(8);
    }
  }, [map, selectedUseCase]);

  if (loading || !called || suspectListLoading || !suspectListCalled)
    return <Loading variant="by-primary" />;
  if (error) return <p>{error.toString()}</p>;
  if (suspectListError) return <p>{suspectListError.toString()}</p>;

  return (
    <div className="app-container">
      <LeftMenu />
      <div id="observations" className={type?.toLowerCase()}>
        {selectedUseCase || selectedSuspectList ? (
          <>
            {!currentObservationId && (
              <>
                <ObservationsMapControl
                  useCases={data.useCases}
                  suspectLists={suspectListData.suspectLists}
                  selectedSuspectList={selectedSuspectList}
                  onSuspectListChange={onSuspectListChange}
                  user={user}
                  type={type}
                  selectedUseCase={selectedUseCase}
                  selectedCam={selectedCam}
                  selectedLocation={selectedLocation}
                  showMap={showMap}
                  onCamChange={setSelectedCam}
                  onLocationChange={setSelectedLocation}
                  toggleMap={() => setShowMap(!showMap)}
                  onUseCaseChange={onUseCaseChange}
                />
                {showMap && (
                  <UseCaseResultsMap
                    center={mapCenter}
                    onLoad={setMap}
                    sortBy={sortBy}
                    onObsClick={onObsClick}
                    onMapClick={setSelectedCam}
                    useCase={selectedUseCase}
                    selectedLocation={selectedLocation}
                  />
                )}
              </>
            )}
            <UseCaseResultsContainer
              useCase={selectedUseCase}
              suspectList={selectedSuspectList}
              camera={selectedCam}
              unselectCam={() => setSelectedCam(null)}
              location={selectedLocation}
              unselectLocation={() => setSelectedLocation(null)}
              showMap={showMap}
              pagination={pagination}
              type={type}
              updateMap={setSortBy}
            />
          </>
        ) : (
          <p className="empty-message">No areas available</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default withStoreProvider(withApolloProvider(connect(mapStateToProps)(CopUseCaseResults)));
