import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { compose, evolve, prepend, any, propEq } from 'ramda';
import {
  GET_VEHICLE_MAKES,
  GET_VEHICLE_COLORS,
  GET_VEHICLE_TYPES
} from '@/components/queries/SharedQueries';
import Card from './components/Card';
import LabelInput from './components/LabelInput';
import Select from './components/LabelSelect';
import CountrySearchInput from './components/CountrySearchInput';
import LicensePlateSearch from './components/LicensePlateSearch';

const LicensePlate = styled(LabelInput)`
  min-width: 93px;
  margin: 6px 12px;
`;

const stringToOption = value => ({ id: value, name: value });

const idMatches = propEq('id');

const getOptions = (data, key, value) => {
  const array = data ? data[key] : [];

  if (!value || any(idMatches(value), array)) {
    return array;
  }

  return prepend(stringToOption(value.toUpperCase()), array);
};

const VehicleCard = ({ formik }) => {
  const { data: vehicleTypeData, loading: typesLoading } = useQuery(GET_VEHICLE_TYPES);
  const { data: vehicleMakeData, loading: makesLoading } = useQuery(GET_VEHICLE_MAKES);
  const { data: vehicleColorData, loading: colorsLoading } = useQuery(GET_VEHICLE_COLORS);

  const {
    vehicleCountry,
    vehicleLicensePlateNumber,
    vehicleType,
    vehicleMake,
    vehicleColor,
    vehicleInformationValidation,
    type
  } = formik.values;
  const disabled =
    (type === 'juridical' || type === 'taxi') && vehicleInformationValidation === 'notallowed';

  const vehicleTypeOptions = getOptions(vehicleTypeData, 'vehicleTypes', vehicleType);
  const vehicleMakeOptions = getOptions(vehicleMakeData, 'vehicleMakes', vehicleMake);
  const vehicleColorOptions = getOptions(vehicleColorData, 'vehicleColors', vehicleColor);

  const getFormattedFieldProp = compose(
    evolve({ value: v => v?.toUpperCase() || '' }),
    formik.getFieldProps
  );

  const onVehicleInfoChange = async info => {
    await formik.setValues({ ...formik.values, ...info });
    formik.validateForm();
  };

  const onVehicleInfoError = () => {
    const error = I18n.t('tickets.form.sections.vehicle_card.vehicle_info_error');
    formik.setFieldError('vehicleLicensePlateNumber', error);
  };

  return (
    <Card
      title={I18n.t('tickets.form.title.vehicle')}
      colorScheme={{ primaryColor: '#CB444A', secondaryColor: '#FDF6F6' }}
      disabled={disabled}
      icon={
        <LicensePlateSearch
          onChange={onVehicleInfoChange}
          onError={onVehicleInfoError}
          country={vehicleCountry}
          licensePlate={vehicleLicensePlateNumber}
        />
      }
    >
      <div className="flex-between">
        <CountrySearchInput
          label={I18n.t('activerecord.attributes.ticket.vehicle_country')}
          disabled={disabled}
          readOnly={formik.status.readOnly}
          error={formik.errors.vehicleCountry ? I18n.t('forms.validation.required_short') : ''}
          attr="code"
          fieldProps={formik.getFieldProps('vehicleCountry')}
        />
        <LicensePlate
          readOnly={formik.status.readOnly}
          label={I18n.t('activerecord.attributes.ticket.vehicle_license_plate_number')}
          error={formik.errors.vehicleLicensePlateNumber}
          disabled={disabled}
          {...formik.getFieldProps('vehicleLicensePlateNumber')}
          controlled
        />
        <LabelInput
          readOnly={formik.status.readOnly}
          label={I18n.t('activerecord.attributes.ticket.vehicle_license_plate_duplicate_code')}
          disabled={disabled}
          {...formik.getFieldProps('vehicleLicensePlateDuplicateCode')}
        />
      </div>
      <Select
        disabled={typesLoading || vehicleTypeOptions.length === 0 || disabled}
        readOnly={formik.status.readOnly}
        label={I18n.t('activerecord.attributes.ticket.vehicle_type')}
        options={vehicleTypeOptions}
        placeholder=""
        error={formik.errors.vehicleType}
        {...getFormattedFieldProp('vehicleType')}
      />
      <Select
        disabled={makesLoading || vehicleMakeOptions.length === 0 || disabled}
        readOnly={formik.status.readOnly}
        label={I18n.t('activerecord.attributes.ticket.vehicle_make')}
        options={vehicleMakeOptions}
        placeholder=""
        error={formik.errors.vehicleMake}
        {...getFormattedFieldProp('vehicleMake')}
      />
      <Select
        disabled={colorsLoading || vehicleColorOptions.length === 0 || disabled}
        readOnly={formik.status.readOnly}
        label={I18n.t('activerecord.attributes.ticket.vehicle_color')}
        options={vehicleColorOptions}
        placeholder=""
        error={formik.errors.vehicleColor}
        {...getFormattedFieldProp('vehicleColor')}
      />
    </Card>
  );
};

export default VehicleCard;
