const CameraIconDirection = (rotation = 0) => {
  const svg = `
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)" transform="rotate(${rotation - 5} 25 25)">
      <path d="M8 -11L19.2483 28.0068L29.4155 29L42 -11L8 -11Z" fill="url(#paint0_linear)"/>
      <path d="M18.865 18.415C15.2283 21.8034 15.0268 27.4982 18.415 31.135C21.8033 34.7717 27.4982 34.9732 31.135 31.585C34.7717 28.1967 34.9732 22.5018 31.585 18.865C28.1966 15.2283 22.5018 15.0268 18.865 18.415Z" fill="#28A745" stroke="white" stroke-width="0.833333"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="22.5" y1="-3" x2="23.5" y2="14.5" gradientUnits="userSpaceOnUse">
        <stop offset="0.14005" stop-color="#28A745" stop-opacity="0"/>
        <stop offset="1" stop-color="#28A745"/>
      </linearGradient>
      <clipPath id="clip0">
        <rect x="50" y="1.90735e-06" width="50" height="50" transform="rotate(90 50 1.90735e-06)" fill="white"/>
      </clipPath>
    </defs>
    </svg>
  `;

  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`; // eslint-disable-line no-undef
};

export default CameraIconDirection;
