import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Button, Modal, Form as BYForm } from 'brickyard-ui';

import DeleteReasonsSelect from '../shared/observations/DeleteReasonsSelect';

export const DELETE_ALL_USE_CASE_RESULTS = gql`
  mutation deleteAllUseCaseResults($search: UseCaseResultSearch, $reasonId: ID) {
    deleteAllUseCaseResults(search: $search, reasonId: $reasonId) {
      count
    }
  }
`;

export const CONFIRMATION_WORD = 'BRICKYARD';

const DeleteAllCopUseCaseResultBtn = ({ params, count, onDelete, needsReason }) => {
  const message = I18n.t('observations.observations.search.delete_confirmation_message', {
    count
  });
  const [reasonId, setReasonId] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [deleteConfState, setDeleteConfState] = useState({
    value: '',
    error: false
  });

  const completeMutation = () => {
    onDelete();
    setShowModal(false);
    setDeleteConfState({ value: '', error: false });
  };

  const [deleteAllUseCaseResults] = useMutation(DELETE_ALL_USE_CASE_RESULTS, {
    variables: { search: params, reasonId },
    ignoreResults: true,
    onCompleted: completeMutation
  });

  const handleDeleteAll = () => {
    if (needsReason && !reasonId) {
      return;
    }
    if (deleteConfState.value !== CONFIRMATION_WORD) {
      setDeleteConfState({ ...deleteConfState, error: true });
      return;
    }
    deleteAllUseCaseResults();
  };

  const onCloseModal = () => {
    setDeleteConfState({ value: '', error: false });
    setShowModal(false);
  };

  return (
    <>
      <Button variant="light-danger" onClick={() => setShowModal(true)}>
        {I18n.t('observations.observations.search.delete_all_button_label')}
      </Button>
      <Modal
        show={showModal}
        onHide={onCloseModal}
        size="md"
        className="observation-delete-all-confirmation"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('observations.observations.search.delete_all_button_label')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>{message}</b>
          <br />
          {needsReason && (
            <BYForm.Group style={{ marginTop: 20 }}>
              <BYForm.Label>
                {I18n.t('observations.observations.dialogs.reasons.title')}
              </BYForm.Label>
              <DeleteReasonsSelect
                name="deleteReason"
                onChange={evt => setReasonId(evt.target.value)}
                isValid={!!reasonId}
                value={reasonId}
              />
            </BYForm.Group>
          )}
          <BYForm.Group style={{ marginTop: 20 }}>
            <BYForm.Label>
              {I18n.t('observations.observations.dialogs.reasons.confirm_delete', {
                confirmation_word: CONFIRMATION_WORD
              })}
            </BYForm.Label>
            <BYForm.Control
              id="deleteConfirmationWordInput"
              value={deleteConfState.value}
              onChange={e =>
                setDeleteConfState({
                  ...deleteConfState,
                  value: e.target.value.toUpperCase()
                })
              }
              isInvalid={deleteConfState.error}
            />
          </BYForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="by-dark" onClick={() => setShowModal(false)}>
            {I18n.t('actions.cancel')}
          </Button>

          <Button variant="danger" onClick={handleDeleteAll}>
            {I18n.t('observations.observations.search.delete_all_button_label')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAllCopUseCaseResultBtn;
