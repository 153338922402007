import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import formatDate from '@/utils/formatDate';
import MiscListItem from './MiscListItem';
import AuditLogsModal from './AuditLogsModal';
import { useQuery } from 'react-apollo';
import { Loading } from 'brickyard-ui';
import { toPairs, pipe, map } from 'ramda';
import { ShortDateFormat } from '@/utils/DateFormats';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { GET_TICKET_LOGS } from '../../../../queries/tickets';
import { useSelector } from 'react-redux';

const GET_TICKETS_VERSIONS = gql`
  query GetTicketsVersions($ticketId: ID!) {
    ticketsVersions(search: { ticketId: $ticketId }) {
      id
      event
      createdAt
      changeset
      userName
    }
  }
`;

const dateFields = ['updated_at', 'written_at', 'dismissed_at', 'payed_at', 'deleted_at'];
const formatField = (key, value) =>
  dateFields.includes(key) ? formatDate(value, ShortDateFormat) : value || '';

const getDetailedText = pipe(
  toPairs,
  map(([key, value]) => {
    const text = I18n.t('tickets.auditline', {
      attr: key,
      from: formatField(key, value.value[0]),
      to: formatField(key, value.value[1])
    });
    return (
      <span key={key} className="detailed-text">
        <span className="label">{value.label}</span> {text}
      </span>
    );
  })
);

const generateText = auditLog => {
  const generalText = I18n.t('tickets.auditlog', {
    when: formatDate(auditLog.createdAt, ShortDateFormat),
    user: auditLog.userName || '?',
    event: auditLog.event
  });

  const detailedText = getDetailedText(auditLog.changeset);

  return (
    <span>
      <span className="general-text">{generalText}</span> <br /> {detailedText}
    </span>
  );
};

const Wrapper = styled.div`
  .info-wrapper {
    margin: 5px 0 2px;
    font-size: 14px;

    .author,
    .date {
      font-weight: 700;
    }
  }
  p {
    font-size: 12px;
    margin: 2px 7px 5px;

    .general-text {
      font-weight: 500;
    }

    .detailed-text {
      display: block;
      font-weight: 200;

      .label {
        font-weight: 500;
      }
    }
  }

  .logs-modal-icon:hover {
    cursor: pointer;
  }
`;

const AuditLogs = ({ ticketId, useCaseName }) => {
  const { data, loading } = useQuery(GET_TICKETS_VERSIONS, {
    variables: { ticketId },
    skip: !ticketId
  });

  const { data: logsData } = useQuery(GET_TICKET_LOGS, {
    variables: { id: parseInt(ticketId) }
  });

  const logsResponse = logsData?.ticketLogs?.logs || [];
  const [showModal, setShowModal] = useState(false);

  const user = useSelector(state => state.user);
  const canSeePipelineLogs = !!user?.cop_show_pipeline_logs;

  return (
    <Wrapper>
      {canSeePipelineLogs && logsResponse.length > 0 && (
        <>
          <i className="logs-modal-icon float-right">
            <AiOutlineFileSearch size={20} onClick={() => setShowModal(true)} />
          </i>

          <AuditLogsModal
            logsResponse={logsResponse}
            useCaseName={useCaseName}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </>
      )}

      {loading ? (
        <Loading />
      ) : (
        data?.ticketsVersions.map(auditLog => (
          <MiscListItem
            key={auditLog.id}
            date={formatDate(auditLog.createdAt, ShortDateFormat)}
            author={auditLog.userName || '?'}
            text={generateText(auditLog)}
          />
        ))
      )}
    </Wrapper>
  );
};

export default AuditLogs;
