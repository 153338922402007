import { gql } from 'apollo-boost';

const CREATE_EXEMPTION_DETAIL_MUTATION = gql`
  mutation CreateExemptionDetail($notificationEmail: String) {
    exemptionDetailCreate(notificationEmail: $notificationEmail) {
      id
      notificationEmail
    }
  }
`;

const UPDATE_EXEMPTION_DETAIL_MUTATION = gql`
  mutation UpdateExemptionDetail($id: ID!, $notificationEmail: String) {
    exemptionDetailUpdate(id: $id, notificationEmail: $notificationEmail) {
      id
      notificationEmail
    }
  }
`;

export { CREATE_EXEMPTION_DETAIL_MUTATION, UPDATE_EXEMPTION_DETAIL_MUTATION };
