import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Label from './Label';

const StyledLabel = styled(Label)`
  position: relative;
  input {
    width: 100%;
    height: 34px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    &:active,
    &:focus {
      box-shadow: 0px 0px 4px #007bff;
      border: none;
      outline: none;
    }
    &.invalid {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0px 0px 4px #dc3545;
      }
    }
  }
  .error-message {
    width: max-content;
    font-size: 10px;
    position: absolute;
    bottom: -16px;
    left: 0;
    color: #dc3545;
  }
`;

const defaultMaskOptions = {
  prefix: '€',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false
};

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {}
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number
  })
};

const LabelInput = ({ label, className, error, readOnly, ...props }) => {
  return (
    <StyledLabel className={className}>
      {label}
      {readOnly ? (
        <span className="readonly-value">{props.value}</span>
      ) : (
        <>
          <CurrencyInput {...props} />
          {error && <span className="error-message">{error}</span>}
        </>
      )}
    </StyledLabel>
  );
};

export default LabelInput;
