import React, { useEffect, useImperativeHandle } from 'react';
import { Loading } from 'brickyard-ui';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { GET_WHITELISTS } from '../../../../../../../queries/SharedQueries';
import { blacklistCheckerSchema } from './validationSchema';
import FormField from '../../../../components/FormField';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function BlacklistChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const { loading: blacklistLoading, data: blacklistData } = useQuery(GET_WHITELISTS);

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      blacklist_id: selectedProcessor?.config?.blacklist_id ?? 0
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'name', 'blacklist_id'])
      );
    },
    validationSchema: blacklistCheckerSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: {
        blacklist_id: parseInt(values.blacklist_id)
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <>
      {!blacklistLoading ? (
        <>
          <FormFieldBlock
            field={
              <>
                <div className="alert alert-success" role="alert">
                  {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
                </div>
                <div className="alert alert-danger" role="alert">
                  {I18n.t('settings.pipelines.processors.form.blacklist_checker.header_info')}
                </div>
              </>
            }
          />

          <FormField
            label={I18n.t('settings.pipelines.processors.form.blacklist_checker.select_list')}
            field={
              <select
                id="blacklist_id"
                name="blacklist_id"
                disabled={!editEnabled}
                onChange={formik.handleChange}
                value={formik.values.blacklist_id}
              >
                <option value=""></option>
                {blacklistData?.whitelists?.map(list => (
                  <option key={list.id} value={list.id}>
                    {list.name}
                  </option>
                ))}
              </select>
            }
            error={formik.errors.blacklist_id}
          />
        </>
      ) : (
        <div className="loading-box">
          <Loading />
        </div>
      )}
    </>
  );
}

export default BlacklistChecker;
