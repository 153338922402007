import { useQuery } from 'react-apollo';
import { GET_COUNTRIES } from '../SharedQueries';

const useCountryOptions = () => {
  const { data, loading } = useQuery(GET_COUNTRIES);

  const options = data ? data.countries : [];

  return [options, loading];
};

export default useCountryOptions;
