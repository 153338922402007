import React, { useState, useEffect } from 'react';
import { Button, Tab, Row, Col, Nav, InfoLabel } from 'brickyard-ui';

import RelatedObservationListitem from './RelatedObservationListitem';
import OffenseCard from './OffenseCard';
import ObservationImages from './ObservationImages';
import RequestExemptionButton from './RequestExemptionButton';
import ObservationRelationHeader from './ObservationRelationHeader';
import ResetObservation from './ResetObservation';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const ORGANISATION_LINK_RELATED_SETTING = gql`
  query OrganisationLinkRelatedSetting {
    currentOrganisation {
      linkRelatedObservations
    }
  }
`;

const getObservationList = mainObservation => {
  const { status, relatedObservations } = mainObservation;
  const observations = status === 'validated' ? [] : relatedObservations;
  const mainObsIndex = observations.findIndex(obs => obs.id === mainObservation.id);
  if (mainObsIndex !== -1) {
    // mainObservation contains the related observations list
    observations.splice(mainObsIndex, 1, mainObservation);
  } else {
    const index = observations.findIndex(
      obs => new Date(obs.observedAt).getTime() > new Date(mainObservation.observedAt).getTime()
    );
    index ? observations.splice(index, 0, mainObservation) : observations.push(mainObservation);
  }
  return observations;
};

const ObservationTabs = ({
  mainObservation,
  getObsStatus,
  onRelationChange,
  markTo,
  onReset,
  area,
  type
}) => {
  const [observationList, setObservationList] = useState([]);
  const [linkAllRelatedDefault, setLinkAllRelatedDefault] = useState(false);

  useEffect(() => {
    setObservationList(getObservationList(mainObservation));
  }, [mainObservation]);

  const { data: organisationData } = useQuery(ORGANISATION_LINK_RELATED_SETTING);

  useEffect(() => {
    if (organisationData) {
      setLinkAllRelatedDefault(organisationData.currentOrganisation.linkRelatedObservations);
    }
  }, [organisationData]);

  const isNotMain = obs => obs.id !== mainObservation.id;

  const relateAll = () => {
    const observations = getObservationList(mainObservation).filter(isNotMain);

    onRelationChange('relate', observations);
  };

  useEffect(() => {
    if (linkAllRelatedDefault) {
      relateAll();
    }
  }, [linkAllRelatedDefault]);

  const unrelateAll = () => {
    const observations = getObservationList(mainObservation).filter(isNotMain);

    onRelationChange('unrelate', observations);
  };

  const deleteAll = () => {
    const observations = getObservationList(mainObservation);
    onRelationChange('delete', observations);
  };

  const deleteUnrelated = () => {
    const observations = getObservationList(mainObservation).filter(
      obs => isNotMain(obs) && getObsStatus(obs) !== 'related'
    );
    onRelationChange('delete', observations);
  };

  return (
    <Tab.Container
      id="observation-tabs"
      defaultActiveKey={mainObservation.id}
      mountOnEnter
      unMountOnExit
    >
      <Row noGutters className="full-height">
        <Col sm={3} className="full-height">
          <ObservationRelationHeader
            relateAll={relateAll}
            unrelateAll={unrelateAll}
            deleteAll={deleteAll}
            deleteUnrelated={deleteUnrelated}
            info={
              type === 'scancar' ? (
                <InfoLabel
                  title={I18n.t('scancar.observations.index.vehicle')}
                  text={mainObservation.camera.name}
                />
              ) : (
                <InfoLabel title={I18n.t('observations.areas.index.name')} text={area.name} />
              )
            }
          />
          <div className="list-container">
            <Nav variant="by-vertical">
              {observationList.map(o => (
                <Nav.Item key={o.id}>
                  <Nav.Link eventKey={o.id}>
                    <RelatedObservationListitem
                      isMain={mainObservation.id === o.id}
                      observation={o}
                      status={getObsStatus(o)}
                      onRelationChange={newStatus => {
                        onRelationChange(newStatus, [o]);
                      }}
                    />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="observation-actions">
            {mainObservation.status !== 'validated' || mainObservation.validatedForDeletion ? (
              <Row noGutters>
                {!mainObservation.exempt &&
                !mainObservation.delayed &&
                mainObservation.ticketable ? (
                  <>
                    <Col sm={4}>
                      <Button variant="warning" size="sm" onClick={() => markTo('warning')}>
                        {I18n.t('observations.observations.details.to_warning')}
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <Button variant="danger" size="sm" onClick={() => markTo('ticket')}>
                        {I18n.t('observations.observations.details.to_ticket')}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col sm={8} />
                )}
                <Col sm={4}>
                  {mainObservation.status !== 'validated' && !mainObservation.exempt && (
                    <RequestExemptionButton observation={mainObservation} />
                  )}
                </Col>
              </Row>
            ) : (
              <Row noGutters>
                <Col sm={12}>
                  <ResetObservation observationId={mainObservation.id} onReset={onReset} isButton />
                </Col>
              </Row>
            )}
          </div>
        </Col>

        <Col sm={9} className="full-height">
          <Tab.Content className="full-height">
            {observationList.map(o => (
              <Tab.Pane key={o.id} eventKey={o.id} className="full-height">
                <Row noGutters className="full-height observation-form-container">
                  <Col sm={6}>
                    {!!o.files && !!o.files.length && (
                      <ObservationImages observation={o} type={type} />
                    )}
                  </Col>

                  <Col sm={6} className="right-container">
                    <OffenseCard
                      observation={o}
                      mainObservationId={mainObservation.id}
                      type={type}
                    />
                  </Col>
                </Row>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ObservationTabs;
