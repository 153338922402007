import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormControls from '../../../components/FormControls';
import { useDispatch, useSelector } from 'react-redux';
import SideBySideSelectorLive from '../../../components/SideBySideSelectorLive';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { useMutation } from 'react-apollo';
import {
  CREATE_USE_CASE_ZONE_MUTATION,
  DELETE_USE_CASE_ZONE_MUTATION,
  UPDATE_USE_CASE_CAMERA_MUTATION,
  UPDATE_USE_CASE_ZONE_MUTATION,
  UPDATE_USE_CASE_MUTATION
} from '../mutations';
import { parseGraphQLErrors } from '../../../../../../../utils/errors';
import { Alert } from 'brickyard-ui';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import UseCaseCameraZone from './UseCaseCameraZone';
import UseCaseCamera from './UseCaseCamera';
import FormField from '../../../components/FormField';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';

const StyledUseCaseCameras = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .dashboard-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .footer {
    display: flex;
    margin: auto;
    width: 100%;
  }

  .uses-by-mobile {
    margin: -10px auto 10px auto;
    min-height: 0;
  }
`;

function UseCaseCameras({ useCase, cameras, onDelete }) {
  const [editEnabled, setEditEnabled] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { actions } = useUseCasesSlice();
  const { selectedUseCaseZone } = useSelector(selectUseCasesState);

  const match = useRouteMatch();
  const state = useSelector(selectUseCasesState);
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  const selectedCameras = useCase.cameras;
  const availableCameras = cameras.filter(
    camera => !selectedCameras?.find(selectedCamera => selectedCamera.id === camera.id)
  );

  useEffect(() => {
    dispatch(actions.fetchSelectedUseCaseZone(useCase.id));
  }, [useCase.id]);

  const formik = useFormik({
    initialValues: {
      availableCameras,
      selectedCameras,
      id: selectedUseCaseZone?.zoneId ?? '',
      name: selectedUseCaseZone?.name ?? '',
      camera_id: selectedUseCaseZone?.id ?? '',
      roof_zone_id: selectedUseCaseZone?.meta?.roof_zone_id ?? '',
      zone_group_id: selectedUseCaseZone?.meta?.zone_group_id ?? '',
      parking_code: selectedUseCaseZone?.meta?.parking_code ?? '',
      use_case_camera_enabled: selectedUseCaseZone?.useCaseCameraEnabled ?? false,
      camera_is_active: selectedUseCaseZone?.active,
      uses_by_mobile: state.selectedUseCase.usesByMobile
    },
    enableReinitialize: true,
    validationSchema: null
  });

  const [createUseCaseZone] = useMutation(CREATE_USE_CASE_ZONE_MUTATION, {
    onError: errorCreate => {
      setSubmitError(errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const [deleteUseCaseZone] = useMutation(DELETE_USE_CASE_ZONE_MUTATION, {
    onError: errorCreate => {
      setSubmitError(errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const [updateUseCaseZone] = useMutation(UPDATE_USE_CASE_ZONE_MUTATION, {
    onError: errorCreate => {
      setSubmitError(errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const [updateUseCaseCamera] = useMutation(UPDATE_USE_CASE_CAMERA_MUTATION, {
    onError: errorCreate => {
      setSubmitError(errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const selectCamera = async camera => {
    const newSelectedCameras = [...formik.values.selectedCameras, camera];

    setSubmitError(false);
    await createUseCaseZone({
      variables: {
        type: camera.type,
        camera: parseInt(camera.id),
        cameras: newSelectedCameras.map(cam => parseInt(cam.id)),
        useCaseId: parseInt(state.selectedUseCase.id)
      }
    }).then(response => {
      if (response) {
        // eslint-disable-next-line no-undef
        const newCameras = _.unionBy(response.data.useCaseZoneCreate.cameras, cameras, 'id');
        dispatch(actions.storeCameras(newCameras));
        dispatch(actions.storeUseCase(response.data.useCaseZoneCreate));
      }
    });
  };

  const deselectCameraConfirmation = async camera => {
    setSubmitError(false);
    setShowDeleteConfirmation(camera);
  };

  const deselectCamera = async camera => {
    const newSelectedCameras = formik.values.selectedCameras?.filter(c => c.id !== camera.id);

    setShowDeleteConfirmation(false);

    await deleteUseCaseZone({
      variables: {
        type: camera.type,
        camera: parseInt(camera.id),
        cameras: newSelectedCameras.map(cam => parseInt(cam.id)),
        useCaseId: parseInt(state.selectedUseCase.id)
      }
    }).then(response => {
      if (response) {
        // eslint-disable-next-line no-undef
        const newCameras = _.unionBy(response.data.useCaseZoneDelete.cameras, cameras, 'id');
        dispatch(actions.storeCameras(newCameras));
        dispatch(actions.storeUseCase(response.data.useCaseZoneDelete));
        dispatch(actions.storeUseCaseZone(null));
      }
    });
  };

  const editZone = async camera => {
    const location = match.url.replace(/\/$/, '');
    history.push(`${location}/zone/${camera.zoneId}`);
    dispatch(actions.storeUseCaseZone(camera));
  };

  const editCamera = async camera => {
    const location = match.url.replace(/\/$/, '');
    history.push(`${location}/camera/${camera.id}`);
    dispatch(actions.storeUseCaseZone(camera));
  };

  const cameraSortFn = (a, b) => {
    return a.id - b.id;
  };
  const getAvailableFixedCameras = () => {
    return formik.values.availableCameras
      ?.filter(camera => camera.type === 'Cop::FixedCamera')
      .sort(cameraSortFn);
  };
  const getSelectedFixedCameras = () => {
    return formik.values.selectedCameras
      ?.filter(camera => camera.type === 'Cop::FixedCamera')
      .sort(cameraSortFn);
  };
  const getAvailableMobileCameras = () => {
    return formik.values.availableCameras
      ?.filter(camera => camera.type === 'Cop::MobileCamera')
      .sort(cameraSortFn);
  };
  const getSelectedMobileCameras = () => {
    return formik.values.selectedCameras
      ?.filter(camera => camera.type === 'Cop::MobileCamera')
      .sort(cameraSortFn);
  };

  const [updateUseCase] = useMutation(UPDATE_USE_CASE_MUTATION);

  const handleSave = async () => {
    setSubmitError(false);
    const zoneId = parseInt(formik.values?.id);

    await updateUseCase({
      variables: {
        id: state.selectedUseCase.id,
        usesByMobile: formik.values?.uses_by_mobile ?? false
      }
    }).then(response => {
      if (!response.errors) {
        dispatch(actions.storeUseCase(response.data.useCaseUpdate));
        dispatch(actions.fetchUseCases());
      }
    });

    if (zoneId) {
      await updateUseCaseZone({
        variables: {
          id: zoneId,
          roofZoneId: formik.values?.roof_zone_id,
          zoneGroupId: formik.values?.zone_group_id,
          parkingCode: formik.values?.parking_code,
          useCaseCameraEnabled: formik.values?.use_case_camera_enabled,
          useCaseId: parseInt(state.selectedUseCase.id)
        }
      }).then(response => {
        if (response) {
          setEditEnabled(false);
          dispatch(actions.storeUseCaseZone(null));
          dispatch(actions.storeUseCase(response.data.useCaseZoneUpdate));
        }
      });
    } else {
      await updateUseCaseCamera({
        variables: {
          id: formik.values?.camera_id,
          useCaseId: parseInt(state.selectedUseCase.id),
          useCaseCameraEnabled: formik.values?.use_case_camera_enabled
        }
      }).then(response => {
        if (response) {
          setEditEnabled(false);
          dispatch(actions.storeUseCaseZone(null));
          dispatch(actions.storeUseCase(response.data.useCaseCameraUpdate));
        }
      });
    }
  };
  const handleCancel = () => {
    setEditEnabled(false);
    setSubmitError(false);

    dispatch(actions.storeUseCaseZone(null));
  };
  const handleDelete = () => {
    onDelete();
  };

  useEffect(() => {
    if (selectedUseCaseZone === null) {
      history.push(match.url);
    }
  }, [selectedUseCaseZone]);

  return (
    <StyledUseCaseCameras className="cop-uc-sources">
      <div className="body">
        <div className="details-left-panel">
          {submitError &&
            submitError.map((error, index) => {
              return (
                <Alert
                  onClose={() => {
                    setSubmitError(false);
                  }}
                  variant="danger"
                  key={index}
                  dismissible
                >
                  {error}
                </Alert>
              );
            })}

          <h5>{I18n.t('settings.use_case_fixed_cameras_label')}:</h5>
          <SideBySideSelectorLive
            availableObjects={getAvailableFixedCameras()}
            selectedObjects={getSelectedFixedCameras()}
            selectOne={selectCamera}
            deselectOne={deselectCameraConfirmation}
            disabled={!editEnabled}
            editEntry={editZone}
          />
          <h5>{I18n.t('settings.use_case_mobile_cameras_label')}:</h5>
          <SideBySideSelectorLive
            availableObjects={getAvailableMobileCameras()}
            selectedObjects={getSelectedMobileCameras()}
            selectOne={selectCamera}
            deselectOne={deselectCameraConfirmation}
            disabled={!editEnabled}
            editEntry={editCamera}
          />
          <h5>{I18n.t('settings.field_uses_by_mobile')}:</h5>
          <FormField
            className="uses-by-mobile"
            field={
              <LabelToggle
                name="uses_by_mobile"
                isOn={formik.values.uses_by_mobile}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
          />

          <div className="footer">
            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                saveEnabled={selectedUseCaseZone || formik.dirty}
                handleEdit={() => setEditEnabled(true)}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                isDeletable={false}
              />
            )}
          </div>
        </div>

        <Switch>
          <Route path={`${match.path}/zone/:id`}>
            {editEnabled && <UseCaseCameraZone formik={formik} />}
          </Route>
          <Route path={`${match.path}/camera/:id`}>
            {editEnabled && <UseCaseCamera formik={formik} />}
          </Route>
        </Switch>
      </div>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={!!showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_use_case_camera_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: () => deselectCamera(showDeleteConfirmation)
          }}
        />
      )}
    </StyledUseCaseCameras>
  );
}

export default UseCaseCameras;
