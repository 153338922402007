import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation, withApollo } from 'react-apollo';
import { Button, Col, Container, Form as BYForm, Loading, MessagePopup, Row } from 'brickyard-ui';
import OffenseSelector from '../offenses/OffenseSelector';
import RepeatOffenderButton from './RepeatOffenderButton';
import VVRRulesDialog from './VVRRulesDialog';
import Close from '@/assets/img/close_icon.svg';
import TestBoundary from '@/utils/TestBoundary';
import Api from '@/utils/Api';

import {
  GET_VEHICLE_MAKES,
  GET_VEHICLE_COLORS,
  GET_VEHICLE_TYPES,
  GET_COUNTRIES
} from '@/components/queries/SharedQueries';

import { GET_DETAILED_OBSERVATION } from '@/components/queries/observations';
import { setCurrentObservation } from '../../../actions/observationActions';

export const UPDATE_OBSERVATION = gql`
  mutation UpdateObservation(
    $id: ID!
    $vehicleLicensePlateNumber: String
    $countryCode: String
    $vehicleType: String
    $offenseCode: String
    $offenseLocationStreet: String
    $vehicleMake: String
    $vehicleColor: String
  ) {
    updateObservation(
      id: $id
      vehicleLicensePlateNumber: $vehicleLicensePlateNumber
      countryCode: $countryCode
      vehicleType: $vehicleType
      offenseCode: $offenseCode
      offenseLocationStreet: $offenseLocationStreet
      vehicleMake: $vehicleMake
      vehicleColor: $vehicleColor
    ) {
      id
      vehicleLicensePlateNumber
      countryCode
      vehicleType
      offenseCode
      offenseLocationStreet
      vehicleMake
      vehicleColor
      vehicleHeading
      relatedObservations {
        id
        countryCode
        vehicleHeading
        accuracy
      }
    }
  }
`;

const generateUpdateObservationQuery = observations => {
  const mutationQuery = observations.map(
    (observation, index) => `
    updateObservation${index}: updateObservation(
      id: "${observation.id}"
      vehicleLicensePlateNumber: $vehicleLicensePlateNumber
      countryCode: $countryCode
      vehicleType: $vehicleType
      offenseCode: $offenseCode
      offenseLocationStreet: $offenseLocationStreet
      vehicleMake: $vehicleMake
      vehicleColor: $vehicleColor
    ) {
      id
      vehicleLicensePlateNumber
      countryCode
      vehicleType
      offenseCode
      offenseLocationStreet
      vehicleMake
      vehicleColor
      vehicleHeading
      relatedObservations {
        id
        countryCode
        vehicleHeading
        accuracy
      }
    }
  `
  );

  return `
    mutation UpdateObservation(
      $vehicleLicensePlateNumber: String
      $countryCode: String
      $vehicleType: String
      $offenseCode: String
      $offenseLocationStreet: String
      $vehicleMake: String
      $vehicleColor: String
    ) {
      ${mutationQuery}
    }
  `;
};

const ObservationForm = ({
  observation,
  canEdit,
  onCancel,
  isModal,
  mainObservationId,
  type,
  client
}) => {
  const [disableField, setDisableField] = useState(isModal ? !canEdit : true);
  const dispatch = useDispatch();

  const { data: countriesData, loading: countriesLoading } = useQuery(GET_COUNTRIES);
  const { data: vehicleMakesData, loading: makesLoading } = useQuery(GET_VEHICLE_MAKES);
  const { data: vehicleColorsData, loading: colorsLoading } = useQuery(GET_VEHICLE_COLORS);
  const { data: vehicleTypeData, loading: typesLoading } = useQuery(GET_VEHICLE_TYPES);
  const [updateObservation, { loading, error }] = useMutation(UPDATE_OBSERVATION);

  const getFormValues = () => {
    const fieldArr = [
      'id',
      'vehicleLicensePlateNumber',
      'countryCode',
      'vehicleType',
      'offenseCode',
      'offenseLocationStreet',
      'vehicleMake',
      'vehicleColor'
    ];
    return Object.keys(observation).reduce((formValues, key) => {
      if (fieldArr.includes(key)) {
        return { ...formValues, [key]: observation[key] || '' };
      }
      return formValues;
    }, {});
  };

  const vehicleDataKeyMap = {
    maker: 'vehicleMake',
    color: 'vehicleColor',
    type: 'vehicleType'
  };

  const formik = useFormik({
    initialValues: {
      ...getFormValues(observation),
      updateAll: true
    },
    enableReinitialize: true,
    onSubmit: async values => {
      const { updateAll, ...variables } = values;

      if (updateAll) {
        const updateObservationQuery = generateUpdateObservationQuery(
          observation.relatedObservations
        );

        await client.mutate({
          mutation: gql(updateObservationQuery),
          variables
        });
      } else {
        await updateObservation({
          refetchQueries: [
            {
              query: GET_DETAILED_OBSERVATION,
              variables: { id: mainObservationId, areaId: observation.area.id }
            }
          ],
          awaitRefetchQueries: true,
          variables: values
        });
      }
      setDisableField(true);
      await parseVehicleData(values);
    },
    validationSchema: Yup.object().shape({
      vehicleLicensePlateNumber: Yup.string().required(),
      countryCode: Yup.string(),
      vehicleType: Yup.string(),
      vehicleMake: Yup.string(),
      vehicleColor: Yup.string(),
      offenseCode: Yup.string(),
      offenseLocationStreet: Yup.string()
    })
  });

  useEffect(() => {
    formik.setValues({ ...getFormValues(observation), updateAll: formik.values.updateAll });
  }, [observation]);

  const cancelEdit = () => {
    formik.handleReset();
    setDisableField(true);
    onCancel();
  };

  const parseVehicleData = async values => {
    const { data: vehicleData } = await Api.get(
      `/services/vehicle/${values.vehicleLicensePlateNumber},${values.countryCode}`
    );

    const parsedVehicleData = Object.keys(vehicleDataKeyMap).reduce((newObj, key) => {
      if (vehicleData?.vehicle_information && vehicleData.vehicle_information[key]) {
        newObj[vehicleDataKeyMap[key]] = vehicleData.vehicle_information[key];
      } else {
        newObj[vehicleDataKeyMap[key]] = '';
      }
      return newObj;
    }, {});

    if (values.vehicleLicensePlateNumber !== observation.vehicleLicensePlateNumber) {
      values = { ...values, ...parsedVehicleData };
    }

    const { updateAll, ...variables } = values;

    if (updateAll) {
      const updateObservationQuery = generateUpdateObservationQuery(
        observation.relatedObservations
      );

      await client.mutate({
        mutation: gql(updateObservationQuery),
        variables
      });
    }
    await updateObservation({
      refetchQueries: [
        {
          query: GET_DETAILED_OBSERVATION,
          variables: { id: mainObservationId, areaId: observation.area.id }
        }
      ],
      awaitRefetchQueries: true,
      variables: values
    });
  };

  const dependsOnLicensePlateAndCountry = () => {
    return (
      disableField ||
      formik.initialValues.vehicleLicensePlateNumber !== formik.values.vehicleLicensePlateNumber ||
      formik.initialValues.countryCode !== formik.values.countryCode
    );
  };

  const resetDependentRelatedFields = evt => {
    ['vehicleType', 'vehicleMake', 'vehicleColor', 'offenseCode', 'offenseLocationStreet'].forEach(
      field => {
        formik
          .getFieldProps(field)
          .onChange({ target: { name: field, value: formik.initialValues[field] || '' } });
      }
    );
    formik.getFieldProps(evt.target.name).onChange(evt);
  };

  const shouldShowUpdateAll = () => {
    return !!(
      observation.relatedObservations &&
      observation.relatedObservations.length &&
      canEdit &&
      !disableField
    );
  };

  if (countriesLoading || makesLoading || colorsLoading || typesLoading) {
    return null;
  }

  return (
    <Container fluid className="observation-form">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          {disableField ? (
            <>
              <Col xs="5" className="observation-form-license-plate">
                <div>{observation.countryCode}</div>
                <div>{formik.values.vehicleLicensePlateNumber}</div>
              </Col>
              <Col xs="6" className="observation-form-offenses">
                <>
                  {!!observation.previousTickets.count && (
                    <RepeatOffenderButton
                      times={observation.previousTickets.count}
                      offenseCode={observation.offenseCode}
                      lastDatetime={observation.previousTickets.lastDatetime}
                      licensePlate={observation.vehicleLicensePlateNumber}
                      observationId={observation.id}
                    />
                  )}
                  {!!observation.previousWarnings.count && (
                    <RepeatOffenderButton
                      type="warning"
                      times={observation.previousWarnings.count}
                      offenseCode={observation.offenseCode}
                      lastDatetime={observation.previousWarnings.lastDatetime}
                      licensePlate={observation.vehicleLicensePlateNumber}
                      observationId={observation.id}
                    />
                  )}
                </>
              </Col>
            </>
          ) : (
            <>
              <Col xs="4">
                <BYForm.Group>
                  <BYForm.Label>
                    {I18n.t('observations.observations.details.country_code')}
                  </BYForm.Label>
                  <BYForm.Control
                    disabled={disableField}
                    readOnly={disableField}
                    size={isModal ? 'md' : 'sm'}
                    as="select"
                    {...formik.getFieldProps('countryCode')}
                    onChange={resetDependentRelatedFields}
                  >
                    <option />
                    {!!(
                      observation.countryCode &&
                      !countriesData.countries.find(c => c.code === observation.countryCode)
                    ) && <option value={observation.countryCode}>{observation.countryCode}</option>}
                    {countriesData.countries.map(c => (
                      <option value={c.code} key={c.id}>
                        {c.code}
                      </option>
                    ))}
                  </BYForm.Control>
                </BYForm.Group>
              </Col>
              <Col xs="4">
                <BYForm.Group>
                  <BYForm.Label>
                    {I18n.t('observations.observations.details.license_plate')}
                  </BYForm.Label>
                  <BYForm.Control
                    disabled={disableField}
                    readOnly={disableField}
                    size={isModal ? 'md' : 'sm'}
                    {...formik.getFieldProps('vehicleLicensePlateNumber')}
                    onChange={resetDependentRelatedFields}
                    className="license-plate-input"
                  />
                </BYForm.Group>
              </Col>
            </>
          )}
          <Col xs={{ span: 1, offset: disableField ? 0 : 3 }} className="p-2">
            <img
              src={Close}
              alt="close"
              className="clickable"
              onClick={() => {
                dispatch(setCurrentObservation(''));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <BYForm.Group>
              <BYForm.Label>{I18n.t('observations.observations.details.make')}</BYForm.Label>
              <BYForm.Control
                disabled={dependsOnLicensePlateAndCountry()}
                readOnly={dependsOnLicensePlateAndCountry()}
                size={isModal ? 'md' : 'sm'}
                as="select"
                {...formik.getFieldProps('vehicleMake')}
              >
                <option />
                {!!(
                  observation.vehicleMake &&
                  !vehicleMakesData.vehicleMakes.includes(observation.vehicleMake)
                ) && <option value={observation.vehicleMake}>{observation.vehicleMake}</option>}
                {vehicleMakesData.vehicleMakes.map(vm => (
                  <option key={vm.id} value={vm.name}>
                    {vm.name}
                  </option>
                ))}
              </BYForm.Control>
            </BYForm.Group>
          </Col>
          <Col xs="4">
            <BYForm.Group>
              <BYForm.Label>
                {I18n.t('observations.observations.details.vehicle_type')}
              </BYForm.Label>
              <BYForm.Control
                disabled={dependsOnLicensePlateAndCountry()}
                readOnly={dependsOnLicensePlateAndCountry()}
                size={isModal ? 'md' : 'sm'}
                as="select"
                {...formik.getFieldProps('vehicleType')}
              >
                <option />
                {!!(
                  observation.vehicleType &&
                  !vehicleTypeData.vehicleTypes
                    .map(vt => {
                      vt.name;
                    })
                    .includes(observation.vehicleType)
                ) && <option value={observation.vehicleType}>{observation.vehicleType}</option>}
                {vehicleTypeData.vehicleTypes.map(vt => (
                  <option key={vt.name} value={vt.name}>
                    {vt.name}
                  </option>
                ))}
              </BYForm.Control>
            </BYForm.Group>
          </Col>
        </Row>
        <Row className="second-row">
          <Col xs="4">
            <BYForm.Group>
              <BYForm.Label>{I18n.t('observations.observations.details.color')}</BYForm.Label>
              <BYForm.Control
                disabled={dependsOnLicensePlateAndCountry()}
                readOnly={dependsOnLicensePlateAndCountry()}
                size={isModal ? 'md' : 'sm'}
                as="select"
                {...formik.getFieldProps('vehicleColor')}
              >
                <option />
                {!!(
                  observation.vehicleColor &&
                  !vehicleColorsData.vehicleColors.includes(observation.vehicleColor)
                ) && <option value={observation.vehicleColor}>{observation.vehicleColor}</option>}
                {vehicleColorsData.vehicleColors.map(vc => (
                  <option key={vc.id} value={vc.name}>
                    {vc.name}
                  </option>
                ))}
              </BYForm.Control>
            </BYForm.Group>
          </Col>
          <Col xs="8" className="col-with-info">
            <TestBoundary>
              <OffenseSelector
                disabled={dependsOnLicensePlateAndCountry()}
                readOnly={dependsOnLicensePlateAndCountry()}
                size={isModal ? 'md' : 'sm'}
                offenseCategory={observation.offenseCategory}
                initialOffense={observation.offense}
                {...formik.getFieldProps('offenseCode')}
              />
            </TestBoundary>
            {!disableField && !loading && (
              <VVRRulesDialog
                observationId={observation.id}
                areaId={observation.area.id}
                onSelect={rule =>
                  formik.setValues({ ...formik.values, offenseCode: rule.appliedData.offenseCode })
                }
              />
            )}
          </Col>
        </Row>
        {type === 'scancar' && (
          <Row>
            <Col xs="8">
              <BYForm.Group>
                <BYForm.Label>
                  {I18n.t('observations.observations.details.offense_location_street')}
                </BYForm.Label>
                <BYForm.Control
                  disabled={dependsOnLicensePlateAndCountry()}
                  readOnly={dependsOnLicensePlateAndCountry()}
                  size={isModal ? 'md' : 'sm'}
                  {...formik.getFieldProps('offenseLocationStreet')}
                />
              </BYForm.Group>
            </Col>
          </Row>
        )}
        {canEdit && (
          <Row className="general-actions">
            {disableField && (
              <Col xs="3">
                <Button
                  variant="by-secondary"
                  size={isModal ? 'md' : 'sm'}
                  block
                  onClick={() => setDisableField(false)}
                >
                  {I18n.t('observations.observations.details.actions.edit')}
                </Button>
              </Col>
            )}
            {!disableField && (
              <>
                <Col xs="3">
                  <Button
                    variant={isModal ? 'by-primary' : 'by-secondary'}
                    size={isModal ? 'md' : 'sm'}
                    block
                    disabled={loading}
                    type="submit"
                  >
                    {I18n.t('actions.save')}
                    {loading && <Loading size="sm" variant="by-dark" />}
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={loading}
                    variant="outline-by-secondary"
                    size={isModal ? 'md' : 'sm'}
                    block
                    onClick={cancelEdit}
                  >
                    {I18n.t('actions.cancel')}
                  </Button>
                </Col>
                {shouldShowUpdateAll() && (
                  <Col xs="6">
                    <BYForm.Switch
                      id="updateAll"
                      disabled={disableField}
                      {...formik.getFieldProps('updateAll')}
                      checked={formik.values.updateAll}
                      label={I18n.t('observations.observations.dialogs.update.update_all')}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        )}
      </form>
      {error && <MessagePopup type="error" size="sm" message={error} />}
    </Container>
  );
};

ObservationForm.defaultProps = {
  client: null,
  observation: {
    vehicleLicensePlateNumber: '',
    countryCode: '',
    vehicleType: '',
    vehicleMake: '',
    vehicleColor: '',
    offenseCode: '',
    offenseLocationStreet: ''
  },
  isModal: false,
  canEdit: true,
  onCancel: () => {}
};

export default withApollo(ObservationForm);
