import React, { useState, useEffect } from 'react';
import { Modal, Loading, Alert } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { selectExemptionsListState, useExemptionsListSlice } from './slice';
import { useFormik } from 'formik';
import exemptionSchema from './validationSchema';
import FormField from '../../../../components/FormField';
import FormControls from '../../../../components/FormControls';
import { EXEMPTION_RESULT_VALUES } from '@/utils/consts';
import { getSelectedTypeScope, getSelectedTypeScopeLabel } from '@/utils/Exemptions';
import { useMutation } from 'react-apollo';
import withApolloProvider from '@/utils/withApolloProvider';
import { CREATE_EXEMPTION_MUTATION } from './mutations';

const ExemptionModal = ({ showModal, setShowModal }) => {
  const { actions } = useExemptionsListSlice();
  const state = useSelector(selectExemptionsListState);
  const dispatch = useDispatch();
  const [customError, setCustomError] = useState('');

  const completeCreateExemption = () => {
    setCustomError('');
    dispatch(actions.fetchExemptionsList());
  };

  const [createExemption, { error }] = useMutation(CREATE_EXEMPTION_MUTATION, {
    onCompleted: completeCreateExemption
  });

  const onSave = async values => {
    await createExemption({ variables: values }).then(() => onClose());
  };

  const onClose = () => {
    setShowModal(false);
    setCustomError('');
    dispatch(actions.storeExemption(null));
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: state.selectedExemption?.id
      ? state.selectedExemption
      : {
          licensePlate: '',
          result: EXEMPTION_RESULT_VALUES()[0].value,
          exemptionTypeId: state.exemptionTypes[0]?.id,
          useCaseId: ''
        },
    enableReinitialize: true,
    onSubmit: values => {
      // eslint-disable-next-line no-undef
      onSave(_.pick(values, ['licensePlate', 'result', 'exemptionTypeId', 'useCaseId']));
    },
    validationSchema: exemptionSchema
  });

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    setCustomError(formik.dirty && error ? error.toString().split(':') : '');
  }, [error, showModal]);

  return (
    <>
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => onClose()}
        className="exemption-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {state.selectedExemption?.id
              ? I18n.t('settings.edit_exemption_modal_title')
              : I18n.t('settings.add_exemption_modal_title')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {customError && <Alert variant="danger">{customError[customError.length - 1]}</Alert>}
          {state.loading ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Form>
              <FormField
                label={I18n.t('settings.field_license_plate_label')}
                field={
                  <input
                    id="licensePlate"
                    name="licensePlate"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.licensePlate ?? ''}
                  />
                }
                error={formik.errors.licensePlate}
              />
              <FormField
                label={I18n.t('settings.field_exemption_type_label')}
                field={
                  <select
                    id="exemptionTypeId"
                    name="exemptionTypeId"
                    onChange={formik.handleChange}
                    value={formik.values.exemptionTypeId}
                  >
                    {state.exemptionTypes.map(exemptionType => (
                      <option key={exemptionType.id} value={exemptionType.id}>
                        {exemptionType.name}
                      </option>
                    ))}
                  </select>
                }
                error={formik.errors.exemptionTypeId}
              />
              <div className={'badges-cell mb-3'}>
                <span
                  className={`badge input-group-badge ${
                    getSelectedTypeScope(state.exemptionTypes, formik) ? 'global' : 'use-case'
                  }`}
                >
                  {getSelectedTypeScopeLabel(state.exemptionTypes, formik)}
                </span>
              </div>
              <div hidden={getSelectedTypeScope(state.exemptionTypes, formik)}>
                <FormField
                  label={I18n.t('settings.field_use_case_label')}
                  field={
                    <select
                      id="useCaseId"
                      name="useCaseId"
                      onChange={formik.handleChange}
                      value={formik.values.useCaseId}
                    >
                      <option value="" />
                      {state.useCases.map(useCase => (
                        <option key={useCase.id} value={useCase.id}>
                          {useCase.name}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.errors.useCaseId}
                />
              </div>
              <FormField
                label={I18n.t('settings.field_result_label')}
                field={
                  <select
                    id="result"
                    name="result"
                    onChange={formik.handleChange}
                    value={formik.values.result}
                  >
                    {EXEMPTION_RESULT_VALUES().map(exemption_result => (
                      <option key={exemption_result.value} value={exemption_result.value}>
                        {exemption_result.label}
                      </option>
                    ))}
                  </select>
                }
                error={formik.errors.result}
              />

              <FormControls
                editEnabled={true}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withApolloProvider(ExemptionModal);
