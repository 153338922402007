import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Modal, Loading } from 'brickyard-ui';
import '@/styles/shared/tickets.scss';
import Button from '../../components/Button';
import Textarea from '../components/Textarea';

const CREATE_TICKET_COMMENT = gql`
  mutation CreateTicketComment($ticketId: ID!, $body: String!) {
    createTicketComment(ticketId: $ticketId, body: $body) {
      id
    }
  }
`;

const NewRemarkModal = ({ ticketId, show, onHide, afterCreate }) => {
  const [body, setBody] = useState('');

  const [createTicketComment, { loading }] = useMutation(CREATE_TICKET_COMMENT, {
    variables: { ticketId, body },
    onCompleted: afterCreate
  });

  const handleAddRemarkClick = evt => {
    evt.preventDefault();
    if (!body) {
      return;
    }
    createTicketComment();
    setBody('');
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" id="offense-clarification-search">
      <Modal.Header closeButton>{I18n.t('tickets.full_ticket.titles.new_comment')}</Modal.Header>

      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <section className="search-result-card-list">
            <Textarea onChange={e => setBody(e.target.value)} />
            <Button onClick={handleAddRemarkClick}>{I18n.t('actions.add')}</Button>
          </section>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NewRemarkModal;
