import React, { useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { parseGraphQLErrors } from '../../../../../../../../utils/errors';
import withApolloProvider from '../../../../../../../../utils/withApolloProvider';
import { Alert } from 'brickyard-ui';
import {
  CREATE_PIPELINE_PROCESSOR_MUTATION,
  UPDATE_PIPELINE_PROCESSOR_MUTATION
} from '../../mutations';

import EmailNotifier from './EmailNotifier';
import EnvironmentalRightChecker from './EnvironmentalRightChecker';
import ExemptionChecker from './ExemptionChecker';
import FollowUpDecorator from './FollowUpDecorator';
import ImageDecorator from './ImageDecorator';
import RailwayFooterDecorator from './RailwayFooterDecorator';
import ObjectTypeChecker from './ObjectTypeChecker';
import OffenseDecorator from './OffenseDecorator';
import P1ExternalBlacklistChecker from './P1ExternalBlacklistChecker';
import PermitChecker from './PermitChecker';
import RailwayViolationChecker from './RailwayViolationChecker';
import RoofDecorator from './RoofDecorator';
import VehicleInfoDecorator from './VehicleInfoDecorator';
import VideoDecorator from './VideoDecorator';
import BlacklistChecker from './BlacklistChecker';
import WhitelistChecker from './WhitelistChecker';
import ExternalRightChecker from './ExternalRightChecker';
import BlueZoneChecker from './BlueZoneChecker';
import ShowSuspectDecorator from './ShowSuspectDecorator';
import SuspectBlurringDecorator from './SuspectBlurringDecorator';
import FooterDecorator from './FooterDecorator';

import NotImplemented from './NotImplemented';
import FormField from '../../../../components/FormField';
import FormControls from '../../../../components/FormControls';
import ExemptionRuleChecker from './ExemptionRuleChecker';
import { selectUseCasesState, useCasesActions } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { getProcessorFullName } from '../../../../../../../../utils/PipelineProcessors';
import EventChecker from './EventChecker';
import MessagePusherNotifier from './MessagePusherNotifier';

const DynamicComponent = ({ className }) => {
  const ref = useRef();
  const { selectedProcessor, selectedPipeline, selectedUseCase } = useSelector(selectUseCasesState);

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();

  const [editEnabled, setEditEnabled] = useState(params.id === 'new');
  const [submitError, setSubmitError] = useState(false);

  const user = useSelector(state => state.user);
  const errors = useSelector(state => state.useCases.errors);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  const [updatePipelineProcessor] = useMutation(UPDATE_PIPELINE_PROCESSOR_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(errorUpdate.message ? parseGraphQLErrors(errorUpdate.message) : false);
    },
    onCompleted: () => {
      dispatch(
        useCasesActions.fetchPipeline({
          useCaseId: selectedUseCase.id,
          pipelineId: selectedPipeline.id
        })
      );

      setSubmitError(false);
      setEditEnabled(false);
    }
  });

  const [createPipelineProcessor] = useMutation(CREATE_PIPELINE_PROCESSOR_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(errorUpdate.message ? parseGraphQLErrors(errorUpdate.message) : false);
    },
    onCompleted: data => {
      dispatch(
        useCasesActions.fetchPipeline({
          useCaseId: selectedUseCase.id,
          pipelineId: selectedPipeline.id
        })
      );

      setSubmitError(false);
      setEditEnabled(false);

      history.push(`${match.url.split('new')[0]}${data.pipelineProcessorCreate.id}`);
    }
  });

  const persistPipelineProcessor = values => {
    if (params.id === 'new') {
      createPipelineProcessor({
        variables: {
          config: values?.variables?.config,
          copPipelineId: selectedPipeline?.id,
          className: selectedProcessor?.className,
          name: values?.variables?.name
        }
      });
    } else {
      updatePipelineProcessor(values);
    }
  };

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    ref?.current?.handleSave();
  };

  const handleCancel = () => {
    if (params.id === 'new') {
      history.push(`${match.url.split('/block')[0]}`);
    } else {
      ref?.current?.handleCancel();
      setSubmitError(false);
      setEditEnabled(false);
    }
  };

  const components = {
    BlacklistChecker,
    BlueZoneChecker,
    EmailNotifier,
    EnvironmentalRightChecker,
    EventChecker,
    ExemptionChecker,
    ExemptionRuleChecker,
    FollowUpDecorator,
    FooterDecorator,
    ImageDecorator,
    MessagePusherNotifier,
    RailwayFooterDecorator,
    ObjectTypeChecker,
    OffenseDecorator,
    P1ExternalBlacklistChecker,
    PermitChecker,
    RailwayViolationChecker,
    RoofDecorator,
    ShowSuspectDecorator,
    SuspectBlurringDecorator,
    VehicleInfoDecorator,
    VideoDecorator,
    WhitelistChecker,
    ExternalRightChecker
  };
  const Component = components[className] || NotImplemented;
  const componentProps = {
    outerRef: ref,
    editEnabled,
    persistPipelineProcessor
  };

  return (
    <>
      <FormField
        label={I18n.t('settings.field_name_label')}
        className="header"
        field={
          <input
            id="name"
            name="name"
            type="text"
            defaultValue={selectedProcessor?.name ?? ''}
            disabled={!editEnabled}
            onChange={ref?.current?.handleChange}
          />
        }
        error={errors?.name}
        extra={
          <>
            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            )}
          </>
        }
      />
      {params.id == 'new' && <b>{getProcessorFullName(className)}</b>}
      {submitError &&
        submitError.map((error, index) => (
          <Alert variant="danger" key={index}>
            {error}
          </Alert>
        ))}
      <Component {...componentProps} />
    </>
  );
};

export default withApolloProvider(DynamicComponent);
