import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { Form as BYForm, Loading, Modal, Card, Button } from 'brickyard-ui';

import useDebounce from '@/hooks/useDebounce';
import ContentEditable from 'react-contenteditable';

import { GET_SUSPECT_STATEMENTS } from '@/components/queries/tickets';

import '@/styles/shared/tickets.scss';

const SuspectStatementSearch = ({ show, onHide, onSelect }) => {
  const [search, setSearch] = useState('');
  const [modalState, setModalState] = useState('searching');

  const editedSuspectStatement = useRef('');

  const debouncedSearch = useDebounce(search, 400);

  const { data, loading } = useQuery(GET_SUSPECT_STATEMENTS, {
    variables: {
      search: { text: debouncedSearch }
    },
    skip: !debouncedSearch
  });

  useEffect(() => {
    setSearch('');
  }, [show]);

  const editableSuspectStatement = os => {
    return addPlaceholders(removeSpans(os));
  };

  const removeSpans = str => {
    return str.replace(/<\/?span[^>]*>/g, '');
  };

  const addPlaceholders = str => {
    return str.replace(/#/g, '<span tabindex="0" class="suspect-statement-placeholder"></span>');
  };

  const selectStatement = os => {
    editedSuspectStatement.current = editableSuspectStatement(os.text);
    setModalState('editing');
  };

  const selectOK = os => {
    onSelect(removeSpans(os));
    setSearch('');
    setModalState('searching');
    onHide();
  };

  const onClose = () => {
    setSearch('');
    setModalState('searching');
    onHide();
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" id="suspect-statement-search">
      <Modal.Header closeButton>
        {I18n.t('activerecord.attributes.ticket.statement_suspect')}
      </Modal.Header>

      {modalState == 'searching' && (
        <Modal.Body>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('actions.search')}</BYForm.Label>

            <BYForm.Control value={search} onChange={evt => setSearch(evt.target.value)} />
          </BYForm.Group>

          <section className="search-result-card-list">
            {loading && <Loading size="sm" />}
            {data?.suspectStatement &&
              data.suspectStatement.map(os => (
                <Card key={os.id} className="result-card" onClick={() => selectStatement(os)}>
                  <Card.Body
                    className="html-result"
                    dangerouslySetInnerHTML={{ __html: os.text }}
                  ></Card.Body>
                </Card>
              ))}
            {!loading && !!search && !data?.suspectStatement?.length && (
              <p>{I18n.t('messages.general.no_results')}</p>
            )}
          </section>
        </Modal.Body>
      )}

      {modalState == 'editing' && (
        <>
          <Modal.Body>
            <ContentEditable
              html={editedSuspectStatement.current}
              onBlur={() => {}}
              onChange={evt => {
                editedSuspectStatement.current = evt.target.value;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <span className="float-right">
              <Button variant="by-dark" onClick={onClose}>
                {I18n.t('actions.cancel')}
              </Button>
              <Button variant="by-primary" onClick={() => selectOK(editedSuspectStatement.current)}>
                {I18n.t('messages.general.ok')}
              </Button>
            </span>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default SuspectStatementSearch;
