import React from 'react';
import { useMutation } from 'react-apollo';
import { Button, InfoIcon, FaTimes, Loading } from 'brickyard-ui';
import { RECORD_UNDO } from '../queries/cop/use_case_results';
import { RESET_USE_CASE_RESULTS } from '../mutations/cop/use_case_results';
import { useDispatch } from 'react-redux';
import { setCurrentObservation } from '../../actions/observationActions';

const ResetUseCaseResult = ({
  useCaseResultId,
  onReset,
  isButton,
  recordUndo,
  defaultLabel = null,
  validatedFilter
}) => {
  const dispatch = useDispatch();
  const [recordUndoMutation] = useMutation(RECORD_UNDO);

  const [resetUseCaseResult, { loading }] = useMutation(RESET_USE_CASE_RESULTS, {
    onCompleted: () => {
      if (recordUndo) {
        recordUndoMutation({ variables: { id: useCaseResultId } });
        dispatch(setCurrentObservation(''));
      }
      onReset(useCaseResultId);
    },
    variables: { ids: [useCaseResultId], validatedFilter }
  });
  const label = defaultLabel ?? I18n.t('observations.observations.list.reset_action');

  return isButton ? (
    <Button onClick={resetUseCaseResult} variant="by-primary">
      {label}
    </Button>
  ) : (
    <i className="reset-observation-icon" onClick={resetUseCaseResult}>
      <InfoIcon Icon={FaTimes} size="md" placement="top" variant="by-dark" text={label} />
      {loading && <Loading size="sm" variant="by-dark" />}
    </i>
  );
};

ResetUseCaseResult.defaultProps = {
  isButton: false,
  recordUndo: false
};

export default ResetUseCaseResult;
